import React, { useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { Card, Form, Input, Button, Checkbox, Row, Col, Select, Modal, message } from 'antd';
import { MailOutlined, LockOutlined, IdcardOutlined } from '@ant-design/icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessModal from './SuccessModal';
import './CreateUserForm.css';

const { Option } = Select;

const initialFormData = {
  firstname: '',
  lastname: '',
  nickname: '',
  nationalid: '',
  date_of_birth: '',
  email: '',
  password: '',
  role: 'teacher',
  phone: '',
  address: '',
  bachelorDegree: '',
  masterDegree: '',
  doctoralDegree: '',
  bachelorSchool: '',
  masterSchool: '',
  doctoralSchool: '',
  specializeSubjects: '',
  hoursRate: '',
  schedule: '',
  isPartTime: false,
  language: [],
};

function CreateTeacherForm({ onClose }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    handleChange('password', password);
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const formattedDate = formData.date_of_birth
        ? new Date(formData.date_of_birth).toISOString().split('T')[0]
        : null;
      const nationalIdValue = formData.nationalid || null;

      const dataToSend = {
        ...formData,
        date_of_birth: formattedDate,
        nationalid: nationalIdValue,
        active: true,
        schedule: JSON.stringify(formData.schedule),
        hoursRate: formData.hoursRate || 0,
      };

      await axios.post(`${API_URL}/users/create`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      message.success(t('form.teacher_created_success'));
      setFormData(initialFormData);
      setShowConfirmModal(false);
      onClose();
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : 'Server error';
      message.error(t(errorMessage));
    }
  };

  return (
    <div className="form-page">
      <Sidebar />
      <Header />
      <div className="form-content">
        <Card title={t('form.add_new_teacher')} bordered={false}>
          <Form layout="vertical" onFinish={() => setShowConfirmModal(true)}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t('form.first_name')} required>
                  <Input
                    value={formData.firstname}
                    onChange={(e) => handleChange('firstname', e.target.value)}
                    placeholder={t('form.first_name')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('form.last_name')} required>
                  <Input
                    value={formData.lastname}
                    onChange={(e) => handleChange('lastname', e.target.value)}
                    placeholder={t('form.last_name')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t('form.nickname')} required>
                  <Input
                    value={formData.nickname}
                    onChange={(e) => handleChange('nickname', e.target.value)}
                    placeholder={t('form.nickname')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('form.email')} required>
                  <Input
                    prefix={<MailOutlined />}
                    type="email"
                    value={formData.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                    placeholder={t('form.email')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t('form.phone')} required>
                  <Input
                    value={formData.phone}
                    onChange={(e) => handleChange('phone', e.target.value)}
                    placeholder={t('form.phone')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('form.temporary_password')} required>
                  <Input.Password
                    prefix={<LockOutlined />}
                    type={showPassword ? 'text' : 'password'}
                    value={formData.password}
                    onChange={(e) => handleChange('password', e.target.value)}
                    placeholder={t('form.temporary_password')}
                    iconRender={(visible) =>
                      visible ? (
                        <FontAwesomeIcon icon={faEye} onClick={togglePasswordVisibility} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} onClick={togglePasswordVisibility} />
                      )
                    }
                  />
                  <Button onClick={generateRandomPassword} type="link">
                    {t('form.generate_password')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={t('form.address')} required>
              <Input.TextArea
                value={formData.address}
                onChange={(e) => handleChange('address', e.target.value)}
                placeholder={t('form.address')}
              />
            </Form.Item>

            <h3>{t('form.academic_details')}</h3>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t('form.bachelor_degree')} required>
                  <Input
                    value={formData.bachelorDegree}
                    onChange={(e) => handleChange('bachelorDegree', e.target.value)}
                    placeholder={t('form.bachelor_degree')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('form.bachelor_school')} required>
                  <Input
                    value={formData.bachelorSchool}
                    onChange={(e) => handleChange('bachelorSchool', e.target.value)}
                    placeholder={t('form.bachelor_school')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t('form.master_degree')}>
                  <Input
                    value={formData.masterDegree}
                    onChange={(e) => handleChange('masterDegree', e.target.value)}
                    placeholder={t('form.master_degree')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('form.master_school')}>
                  <Input
                    value={formData.masterSchool}
                    onChange={(e) => handleChange('masterSchool', e.target.value)}
                    placeholder={t('form.master_school')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Checkbox
                checked={formData.isPartTime}
                onChange={(e) => handleChange('isPartTime', e.target.checked)}
              >
                {t('form.part_time')}
              </Checkbox>
            </Form.Item>

            <Button type="primary" htmlType="submit">
              {t('form.create_teacher')}
            </Button>
          </Form>
        </Card>

        {showConfirmModal && (
          <SuccessModal
            details={formData}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={handleSubmit}
          />
        )}
      </div>
    </div>
  );
}

export default withRoleAccess(CreateTeacherForm, ['superadmin', 'admin']);
