import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Input, Pagination, Modal, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faStar, faTrash, faUser, faUserGraduate, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PasswordModal from './PasswordModal';
import EditStudentModal from './EditStudentModal';
import { refreshToken, isTokenExpiredError } from '../../Utils/authUtils';

function EditDeleteStudent({ onCreateNewStudent }) {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [studentIdToDelete, setStudentIdToDelete] = useState(null);
  const [studentIdToEdit, setStudentIdToEdit] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [nameSearch, setNameSearch] = useState('');
  const [phoneSearch, setPhoneSearch] = useState('');
  const [schoolIdSearch, setSchoolIdSearch] = useState('');
  const rowsPerPage = 100;
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(t('locale'), options).format(date).toUpperCase();
};

  useEffect(() => {
    const fetchStudents = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const queryParams = new URLSearchParams({
          page: currentPage,
          name: nameSearch,
          phoneNumber: phoneSearch,
          schoolId: schoolIdSearch,
        });

        const response = await axios.get(`${API_URL}/students?${queryParams.toString()}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setStudents(response.data.students);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching students:', error);
        if (isTokenExpiredError(error)) {
          refreshToken().then(() => fetchStudents()).catch(err => {
            console.error('Token refresh failed:', err);
            setError(t('sessionExpired'));
          });
        } else {
          setError(t('fetchStudentsError'));
          setLoading(false);
        }
      }
    };

    fetchStudents();
  }, [currentPage, nameSearch, phoneSearch, schoolIdSearch, API_URL, t]);

  const handleEdit = (studentId) => {
    setStudentIdToEdit(studentId);
    setIsEditModalOpen(true);
  };

  const handleDelete = (studentId) => {
    setStudentIdToDelete(studentId);
    setPasswordError('');
    setIsPasswordModalOpen(true);
  };

  const confirmDelete = async (password) => {
    setIsPasswordModalOpen(false);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError(t('noTokenFound'));
        return;
      }
      await axios.delete(`${API_URL}/students/${studentIdToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { password },
      });
      setStudents(prev => prev.filter(student => student.userid !== studentIdToDelete));

      Modal.success({ title: t('success'), content: t('studentDeleted') });
    } catch (error) {
      console.error('Error deleting student:', error);
      if (error.response && error.response.status === 401) {
        setPasswordError(t('invalidPassword'));
        setIsPasswordModalOpen(true);
      } else {
        setError(t('deleteStudentError'));
      }
    }
  };

  const saveStudent = async (updatedStudent) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/students/${updatedStudent.userid}`, updatedStudent, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStudents(prev => prev.map(student => (student.userid === updatedStudent.userid ? updatedStudent : student)));
      setIsEditModalOpen(false);

      Modal.success({ title: t('success'), content: t('studentUpdated') });
    } catch (error) {
      console.error('Error updating student:', error);
      setError(t('updateStudentError'));
    }
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const viewStudentDashboard = (studentId) => {
    navigate(`/student-dashboard-admin/${studentId}`);
  };

  const viewStudentPerformance = (studentId) => {
    navigate(`/studentperformance/${studentId}`);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'dashboard',
      render: (_, student) => (
        <Button icon={<FontAwesomeIcon icon={faUser} />} onClick={() => viewStudentDashboard(student.userid)} />
      ),
    },
    {
      title: '',
      dataIndex: 'performance',
      render: (_, student) => (
        <Button icon={<FontAwesomeIcon icon={faStar} />} onClick={() => viewStudentPerformance(student.userid)} />
      ),
    },
    { title: t('id'), dataIndex: 'schoolid' },
    { title: t('name'), dataIndex: 'name', render: (_, student) => `${student.firstname} ${student.lastname}` },
    { title: t('nickname'), dataIndex: 'nickname' },
    { title: t('dob'), dataIndex: 'date_of_birth', render: formatDate },
    { title: t('age'), dataIndex: 'date_of_birth', render: calculateAge },
    { title: t('email'), dataIndex: 'email' },
    { title: t('phone'), dataIndex: 'phone' },
    { title: t('level'), dataIndex: 'currenteducationlevel' },
    { title: t('school'), dataIndex: 'schoolname' },
    {
      title: t('id'),
      dataIndex: 'national_id_pdf',
      render: (pdf, student) =>
        pdf ? (
          <a href={`${API_URL}/upload/national_ids/${pdf.split('/').pop()}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFilePdf} style={{ color: 'red' }} />
          </a>
        ) : (
          t('no_pdf')
        ),
    },
    {
      title: t('actions'),
      dataIndex: 'actions',
      render: (_, student) => (
        <div>
          <Button icon={<FontAwesomeIcon icon={faEdit} />} onClick={() => handleEdit(student.userid)} />
          <Button icon={<FontAwesomeIcon icon={faTrash} />} onClick={() => handleDelete(student.userid)} />
        </div>
      ),
    },
  ];

  if (loading) return <Spin tip={t('loading')} />;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <Button type="primary" icon={<FontAwesomeIcon icon={faUserGraduate} />} onClick={onCreateNewStudent}>
        {t('createNewStudent')}
      </Button>
      <div style={{ marginTop: 16 }}>
        <Input
          placeholder={t('searchByName')}
          value={nameSearch}
          onChange={(e) => setNameSearch(e.target.value)}
          style={{ marginBottom: 8, width: 200 }}
        />
        <Input
          placeholder={t('searchByPhone')}
          value={phoneSearch}
          onChange={(e) => setPhoneSearch(e.target.value)}
          style={{ marginBottom: 8, width: 200 }}
        />
        <Input
          placeholder={t('searchBySchoolID')}
          value={schoolIdSearch}
          onChange={(e) => setSchoolIdSearch(e.target.value)}
          style={{ marginBottom: 16, width: 200 }}
        />
      </div>
      <Table
  columns={columns}
  dataSource={students}
  rowKey="userid"
  pagination={{
    current: currentPage,
    pageSize: rowsPerPage,
    total: totalPages * rowsPerPage,
    onChange: (page) => setCurrentPage(page),
  }}
  scroll={{ x: 'max-content' }} // Enable horizontal scrolling
/>
      <PasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
        onConfirm={confirmDelete}
        errorMessage={passwordError}
      />
      <EditStudentModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={saveStudent}
        studentId={studentIdToEdit}
      />
    </div>
  );
}

export default withRoleAccess(EditDeleteStudent, ['admin', 'superadmin']);
