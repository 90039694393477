import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faHome, faGraduationCap, faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { QRCodeCanvas } from 'qrcode.react';
import getUserIdFromToken from '../../Utils/authUtils';
import Modal from '../../components/Modal';
import moment from 'moment';
import thLocale from '@fullcalendar/core/locales/th';
import './Student.css';
import { Layout, Card, Row, Col, Typography, Space, Table, Button, Tag, Avatar, Descriptions, Badge, Alert } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, HomeOutlined, CalendarOutlined, BookOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Content } = Layout;
const { Title, Text } = Typography;

function StudentDashboard() {
  const { t, i18n } = useTranslation();
  const userId = getUserIdFromToken();
  const [student, setStudent] = useState(null);
  const [studentTestRegistrations, setStudentTestRegistrations] = useState([]);
  const [error, setError] = useState(null);
  const [studentAttendance, setStudentAttendance] = useState([]);
  const [billingHistory, setBillingHistory] = useState([]);
  const [popupData, setPopupData] = useState(null);
  const [absentCounts, setAbsentCounts] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const API_URL = process.env.REACT_APP_API_URL;

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const getDayOfWeek = (dateString) => {
    try {
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      const daysOfWeek = t('daysOfWeek', { returnObjects: true });
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return t('invalidDay');
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/students/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const studentData = response.data;
        setStudent({ ...studentData, photourl: studentData.photourl || 'https://via.placeholder.com/150' });
      } catch (error) {
        console.error('Error fetching student data:', error);
        setError('Failed to fetch student data.');
      }
    };

    const fetchStudentAttendance = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/student/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentAttendance(response.data);
        calculateAbsences(response.data);
      } catch (error) {
        console.error('Error fetching student attendance:', error);
      }
    };

    const calculateAbsences = (attendanceData) => {
      const absences = {};
      attendanceData.forEach(attendance => {
        const statusLowerCase = attendance.status.toLowerCase();
        if (statusLowerCase === 'absent') {
          if (!absences[attendance.class_code]) {
            absences[attendance.class_code] = 1;
          } else {
            absences[attendance.class_code] += 1;
          }
        }
      });
      setAbsentCounts(absences);
    };

    const fetchBillingHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/user`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const { registrations } = response.data;
        const sortedRegistrations = registrations.map(reg => {
          const sortedSchedule = reg.schedule.sort((a, b) => {
            const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm:ss');
            const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm:ss');
            return dateTimeA - dateTimeB;
          });
          return { ...reg, schedule: sortedSchedule };
        });

        sortedRegistrations.sort((a, b) => {
          const earliestA = a.schedule[0] ? moment(`${a.schedule[0].date} ${a.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          const earliestB = b.schedule[0] ? moment(`${b.schedule[0].date} ${b.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          return earliestA - earliestB;
        });

        setBillingHistory(sortedRegistrations);
      } catch (error) {
        console.error('Error fetching billing history:', error);
      }
    };

    const fetchStudentTestRegistrations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/test-scores/test-registrations`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentTestRegistrations(response.data);
      } catch (error) {
        console.error('Error fetching student test registrations:', error);
      }
    };

    fetchStudentData();
    fetchStudentAttendance();
    fetchBillingHistory();
    fetchStudentTestRegistrations();
  }, [userId, API_URL]);

  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [durationHours, durationMinutes] = duration.split(':').map(Number);
    let endHours = startHours + durationHours;
    let endMinutes = startMinutes + durationMinutes;
    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }
    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  }, [i18n.language]);

  const sortedAndFilteredSchedule = useMemo(() => {
    return billingHistory
      .flatMap(enrollment => 
        enrollment.schedule.map(schedule => ({ ...schedule, class_code: enrollment.class_code }))
      )
      .sort((a, b) => moment(a.date + ' ' + a.time, 'YYYY-MM-DD HH:mm:ss') - moment(b.date + ' ' + b.time, 'YYYY-MM-DD HH:mm:ss'));
  }, [billingHistory]);

  const paginatedSchedule = useMemo(() => {
    return sortedAndFilteredSchedule.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [sortedAndFilteredSchedule, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(sortedAndFilteredSchedule.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
   
  const openSchedulePopup = (data) => {
    setPopupData(data);
  };
  

  const closePopup = () => {
    setPopupData(null);
  };


  const eventContent = (eventInfo) => {
    const { subject, teacher, classroom } = eventInfo.event.extendedProps;
    const startTime = eventInfo.event.startStr.split('T')[1].slice(0, 5);
    const endTime = eventInfo.event.endStr.split('T')[1].slice(0, 5);

    return (
      <div style={{ padding: '5px', fontSize: '0.8em' }}>
        <div style={{ fontWeight: 'bold' }}>{`${startTime} - ${endTime}`}</div>
        <div>{eventInfo.event.title}</div>
        <div>{subject}</div>
        <div>{teacher}</div>
        {classroom && <div>{`Room: ${classroom}`}</div>}
      </div>
    );
  };

  if (error) return <p>{error}</p>;
  if (!student) return <p>Loading...</p>;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ padding: '24px' }}>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
            <Card 
              bordered={false}
              style={{ 
                borderRadius: '16px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
              }}
            >
              <div style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                padding: '24px'
              }}>
                {/* Profile Header */}
                <div style={{
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '32px'
                }}>
                  <Avatar 
                    size={120} 
                    src={student.photourl} 
                    icon={<UserOutlined />}
                    style={{
                      border: '4px solid #fff',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                      marginBottom: '16px'
                    }}
                  />
                  <Title level={3} style={{ margin: '8px 0' }}>
                    {student.firstname} {student.lastname}
                  </Title>
                  <Tag color="blue" style={{ padding: '4px 12px' }}>
                    {student.schoolid}
                  </Tag>
                </div>

                {/* Profile Details */}
                <div style={{ width: '100%' }}>
                  {[
                    { 
                      icon: <MailOutlined style={{ color: '#1890ff' }} />, 
                      label: t('email'), 
                      value: student.email 
                    },
                    { 
                      icon: <PhoneOutlined style={{ color: '#52c41a' }} />, 
                      label: t('phone'), 
                      value: student.phone 
                    },
                    { 
                      icon: <HomeOutlined style={{ color: '#faad14' }} />, 
                      label: t('address'), 
                      value: student.address 
                    },
                    { 
                      icon: <CalendarOutlined style={{ color: '#eb2f96' }} />, 
                      label: t('dob'), 
                      value: formatDate(student.date_of_birth)
                    },
                    { 
                      icon: <BookOutlined style={{ color: '#722ed1' }} />, 
                      label: t('currentEducationLevel'), 
                      value: student.currenteducationlevel 
                    }
                  ].map((item, index) => (
                    <div 
                      key={index}
                      style={{
                        padding: '16px',
                        marginBottom: '12px',
                        background: '#f5f5f5',
                        borderRadius: '12px',
                        transition: 'all 0.3s ease'
                      }}
                      onMouseEnter={e => e.currentTarget.style.transform = 'translateX(8px)'}
                      onMouseLeave={e => e.currentTarget.style.transform = 'translateX(0)'}
                    >
                      <Space align="center">
                        <div style={{
                          padding: '8px',
                          borderRadius: '8px',
                          background: '#fff',
                          marginRight: '12px'
                        }}>
                          {item.icon}
                        </div>
                        <div>
                          <Text type="secondary" style={{ fontSize: '12px', display: 'block' }}>
                            {item.label}
                          </Text>
                          <Text strong style={{ fontSize: '14px' }}>
                            {item.value}
                          </Text>
                        </div>
                      </Space>
                    </div>
                  ))}
                </div>

                {/* QR Code Section */}
                <div style={{ 
                  marginTop: '24px',
                  padding: '16px',
                  background: '#f5f5f5',
                  borderRadius: '12px',
                  textAlign: 'center'
                }}>
                  <Text type="secondary" style={{ display: 'block', marginBottom: '12px' }}>
                    {t('Student ID')}
                  </Text>
                  <QRCodeCanvas 
                    value={student.schoolid} 
                    size={120}
                    style={{
                      padding: '8px',
                      background: '#fff',
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.06)'
                    }}
                  />
                </div>
              </div>
            </Card>
            </Col>

            <Col xs={24} md={16}>
            <Card title={t('calendar')} style={{ marginTop: '24px' }}>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin]}
                initialView="dayGridMonth"
                locale={i18n.language === 'th' ? thLocale : 'en'}
                events={billingHistory.flatMap(enrollment =>
                  enrollment.schedule.map(cls => {
                    const startTime = cls.time.slice(0, 5);
                    const startDateTime = `${cls.date}T${startTime}:00`;
                    const endTime = calculateEndTime(cls.time, cls.hours);
                    const endDateTime = `${cls.date}T${endTime}:00`;

                    return {
                      title: `${enrollment.class_code}`,
                      start: startDateTime,
                      end: endDateTime,
                      color: '#005181',           // Use color instead of backgroundColor
                      backgroundColor: '#005181', // Keep this as backup
                      borderColor: '#005181',    // Add border color
                      textColor: '#ffffff',
                      display: 'block',          // Add display property
                      extendedProps: {
                        subject: cls.subject,
                        teacher: cls.teacher,
                        classroom: cls.classroom,
                      },
                    };
                  })
                )}
                eventDisplay="block"            // Add this prop
                eventColor="#005181"           // Add this prop
                eventBackgroundColor="#005181"
                eventBorderColor="#005181"
                eventTextColor="#ffffff"
                eventContent={eventContent}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek',
                }}
                height="auto"
                slotEventOverlap={false}      // Prevent event overlap
                displayEventTime={true}       // Show event times
                displayEventEnd={true}        // Show event end times
              />
          </Card>
              </Col>
              </Row>
              <Card title={t('classSchedule')} style={{ marginTop: '24px' }}>
  <Table
    dataSource={paginatedSchedule}
    pagination={{
      current: currentPage,
      total: totalPages * itemsPerPage,
      onChange: handlePageChange,
    }}
    columns={[
      { title: t('date'), dataIndex: 'date', render: (date) => formatDate(date) },
      { title: t('classCode'), dataIndex: 'class_code' },
      { title: t('subject'), dataIndex: 'subject' },
      { title: t('teacher'), dataIndex: 'teacher' },
      { title: t('time'), render: (_, cls) => `${formatTime(cls.time)} - ${calculateEndTime(cls.time, cls.hours)}` },
      { title: t('hours'), dataIndex: 'hours', render: (hours) => `${hours} ${t('hr')}` },
      {
        title: t('attendance'),
        render: (_, cls) => {
          const attendance = studentAttendance.find(att => att.classid === cls.classid);
          const status = attendance ? attendance.status : '';
          const colors = { 'Present': 'green', 'Absent': 'red', 'Late': 'orange' };
          return status ? <Tag color={colors[status] || 'default'}>{status}</Tag> : <Tag>{status}</Tag>;
        },
      },
    ]}
    scroll={{ x: 'max-content' }} // Added scroll property for horizontal scrolling
  />
</Card>


          <Card title={t('currentEnrollments')} style={{ marginTop: '24px' }}>
            <div className="student-card-container">
              {billingHistory.map((enrollment, index) => (
                <Card key={index} type="inner" title={enrollment.class_code} extra={<Link to={`/classdetailsstudent/${enrollment.groupid}`}>{t('viewDetails')}</Link>}>
                  <p><strong>{t('Subjects')}:</strong> {enrollment.subjects.map(subject => subject.title).join(', ')}</p>
                  <p><strong>{t('Total Hours')}:</strong> {enrollment.total_hours}</p>
                  <Button type="primary" onClick={() => openSchedulePopup(enrollment.schedule)}>{t('viewSchedule')}</Button>
                </Card>
              ))}
            </div>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRoleAccess(StudentDashboard, ['student']);
