import React from 'react';
import { Modal, Button, Typography, Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './CreateUserForm.css'; // Assuming you have CSS for additional styling

const { Title, Paragraph } = Typography;

function SuccessModal({ details, onClose, onConfirm }) {
  return (
    <Modal
      visible
      title={<Title level={4}>Confirm User Details</Title>}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          Confirm
        </Button>,
      ]}
      closeIcon={<CloseOutlined />}
    >
      <div className="user-details-summary">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Paragraph><strong>First Name:</strong> {details.firstname}</Paragraph>
            <Paragraph><strong>Last Name:</strong> {details.lastname}</Paragraph>
            <Paragraph><strong>Email:</strong> {details.email}</Paragraph>
            <Paragraph><strong>Role:</strong> {details.role}</Paragraph>
          </Col>
          <Col span={12}>
            <Paragraph><strong>Phone:</strong> {details.phone}</Paragraph>
            <Paragraph><strong>Address:</strong> {details.address}</Paragraph>
            <Paragraph><strong>Nickname:</strong> {details.nickname}</Paragraph>
            <Paragraph><strong>National ID:</strong> {details.nationalid}</Paragraph>
            <Paragraph><strong>Date of Birth:</strong> {details.date_of_birth}</Paragraph>
            <Paragraph><strong>Temporary Password:</strong> {details.password}</Paragraph>
          </Col>
        </Row>

        {details.role === 'teacher' && (
          <>
            <Paragraph><strong>Bachelor's Degree:</strong> {details.bachelorDegree}</Paragraph>
            <Paragraph><strong>Master's Degree:</strong> {details.masterDegree}</Paragraph>
            <Paragraph><strong>Doctoral Degree:</strong> {details.doctoralDegree}</Paragraph>
          </>
        )}

        {details.role === 'student' && (
          <>
            <Paragraph><strong>Current Education Level:</strong> {details.currentEducationLevel}</Paragraph>
            <Paragraph><strong>Guardian Phone:</strong> {details.guardianContact}</Paragraph>
          </>
        )}
      </div>
    </Modal>
  );
}

export default SuccessModal;
