import React, { useRef } from 'react';
import './Accounting.css';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

const formatNumber = (number, locale) => {
  if (isNaN(number)) {
    return '0.00';
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

const formatDate = (dateString, locale) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString(locale, options).toUpperCase();
};

const InvoiceModal = ({  show, onClose, registration }) => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="invoice-modal-overlay">
      
      {registration && (
        <div className="invoice-modal" ref={componentRef}>
<span className="modal-close-button" onClick={onClose}>&times;</span>
          <div className="invoice-details">
            <div className="invoice-header">
              <div className="company-info">
                <p><strong>{t('companyName')}</strong></p>
                <p>{t('companyAddress')}</p>
                <p>{t('companyCity')}</p>
                <p>{t('companyTaxID')}: 4852458240095969</p>
                <p>{t('companyPhone')}: 0987654321</p>
              </div>
              <div className="invoice-info">
                <h3>{registration.is_refunded ? t('creditNote') : t('invoice')}</h3>
                <p>{t('invoiceNo')}: {registration.invoiceno}</p>
                <p>{t('date')}: {formatDate(registration.date, i18n.language)}</p>
              </div>
            </div>
            <div className="bill-to">
              <h3>{t('billTo')}:</h3>
              <p>{t('name')}: {registration.student_name}</p>
              <p>{t('address')}: {registration.address || t('na')}</p>
              <p>{t('phone')}: {registration.phone || t('na')}</p>
              <p>{t('class')}: {registration.class_code}</p>
            </div>
            <div className="invoice-table">
              <table>
                <thead>
                  <tr>
                    <th>{t('description')}</th>
                    <th>{t('quantity')}</th>
                    <th>{t('unitPrice')}</th>
                    <th>{t('amount')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('class')}</td>
                    <td>1</td>
                    <td>{formatNumber(registration.price, i18n.language)}</td>
                    <td>{formatNumber(registration.price, i18n.language)}</td>
                  </tr>
                  <tr>
                    <td>{t('discount')}</td>
                    <td></td>
                    <td></td>
                    <td>{formatNumber(-registration.discount, i18n.language)}</td>
                  </tr>
                  <tr>
                    <td>{t('vat', { percentage: 7 })}</td>
                    <td></td>
                    <td></td>
                    <td>{formatNumber(registration.vat, i18n.language)}</td>
                  </tr>
                  <tr>
                    <td><strong>{t('total')}</strong></td>
                    <td></td>
                    <td></td>
                    <td><strong>{formatNumber(registration.final_price, i18n.language)}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="payment-details">
              <p>{t('paymentMethod')}: {registration.payment_method}</p>
              <p>{t('memo')}: {registration.memo || t('na')}</p>
              <p>{t('paymentConfirmed')}</p>
            </div>
            {registration.signature && (
              <div className="signature">
                <p>{t('signature')}:</p>
                <img src={registration.signature} alt="Signature" />
              </div>
            )}
                    <div className="invoice-actions">
          <button onClick={handlePrint}>{t('printReceipt')}</button>
        </div>
          </div>
        </div>
      )}
</div>
);
};

export default InvoiceModal;
