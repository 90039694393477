import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { Layout, Input, DatePicker, Table, Space, Button, Pagination } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Registrations.css';

const { Content } = Layout;
const { RangePicker } = DatePicker;

const TeacherRatingReport = () => {
  const [teacherRatings, setTeacherRatings] = useState([]);
  const [filteredTeacherRatings, setFilteredTeacherRatings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(150);
  const [searchTerm, setSearchTerm] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date)) return 'Invalid date';
    return date.toLocaleDateString(t('locale'), { day: '2-digit', month: 'short', year: 'numeric' }).toUpperCase();
  };

  useEffect(() => {
    const fetchTeacherRatings = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/ratings`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTeacherRatings(response.data);
      } catch (error) {
        console.error('Error fetching teacher ratings:', error);
      }
    };
    fetchTeacherRatings();
  }, [API_URL]);

  useEffect(() => {
    let filtered = teacherRatings;

    if (searchTerm) {
      filtered = filtered.filter((rating) =>
        rating.teacher_nickname.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (fromDate) {
      filtered = filtered.filter((rating) => new Date(rating.date) >= new Date(fromDate));
    }
    if (toDate) {
      filtered = filtered.filter((rating) => new Date(rating.date) <= new Date(toDate));
    }

    setFilteredTeacherRatings(filtered);
    setCurrentPage(1);
  }, [searchTerm, fromDate, toDate, teacherRatings]);

  const currentItems = filteredTeacherRatings.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const columns = [
    { title: t('Teacher'), dataIndex: 'teacher_nickname', key: 'teacher_nickname' },
    { title: t('Class Code'), dataIndex: 'class_code', key: 'class_code' },
    { title: t('Subject'), dataIndex: 'subject_name', key: 'subject_name' },
    {
      title: t('Date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => formatDate(date),
    },
    { title: t('Student'), dataIndex: 'student_nickname', key: 'student_nickname' },
    { title: t('Rating'), dataIndex: 'teacher_rating', key: 'teacher_rating' },
    { title: t('Comments'), dataIndex: 'studentcomment', key: 'studentcomment' },
    {
      title: t('Timestamp'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => formatDate(timestamp),
    },
  ];

  const handleDateRangeChange = (dates) => {
    setFromDate(dates ? dates[0].toDate() : null);
    setToDate(dates ? dates[1].toDate() : null);
  };

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <h1>{t('Teacher Rating Report')}</h1>

          <Space style={{ marginBottom: 16 }}>
            <Input
              placeholder={t('Search by Teacher Name')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: 200 }}
            />
            <RangePicker onChange={handleDateRangeChange} />
            <CSVLink data={currentItems} filename="teacher_rating_report.csv" className="ant-btn">
              <FontAwesomeIcon icon={faFileExcel} /> {t('Export CSV')}
            </CSVLink>
          </Space>

          <Table
            columns={columns}
            dataSource={currentItems}
            pagination={false}
            rowKey={(record, index) => index}
          />
          <Pagination
            current={currentPage}
            total={filteredTeacherRatings.length}
            pageSize={itemsPerPage}
            onChange={setCurrentPage}
            showSizeChanger
            onShowSizeChange={(_, size) => setItemsPerPage(size)}
            style={{ marginTop: 16 }}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(TeacherRatingReport, ['admin', 'superadmin']);
