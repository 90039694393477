import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import getUserIdFromToken from '../../Utils/authUtils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './Teacher.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const generateMonthYearOptions = () => {
  const options = [];
  const startYear = 2024;
  const startMonth = 7; // August (0-indexed, so January is 0, August is 7)
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let year = startYear; year <= currentYear + 5; year++) { // Loop up to the next 5 years
    for (let month = 0; month < 12; month++) {
      // Ensure we only start from August 2024 onwards
      if (year === startYear && month < startMonth) continue;

      // Construct the month-year label
      const monthYear = new Date(year, month).toLocaleString('default', {
        month: 'short',
        year: 'numeric'
      }).toUpperCase();

      options.push({ value: `${year}-${month + 1}`, label: monthYear });

      // Stop if we go past the current month in the current year
      if (year === currentYear && month >= currentMonth) {
        continue;
      }
    }
  }

  return options;
};

const TeacherHours = () => {
  const userId = getUserIdFromToken();
  const { t, i18n } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [activeTab, setActiveTab] = useState('hours');
  const [payoutFilter, setPayoutFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const itemsPerPage = 50;
  const API_URL = process.env.REACT_APP_API_URL;
  const [selectedMonthYear, setSelectedMonthYear] = useState(() => {
    const now = new Date();
    const currentMonthYear = `${now.getFullYear()}-${now.getMonth() + 1}`;
    return currentMonthYear;
  });

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric'};
    return date.toLocaleDateString(i18n.language, options);
  };

  const formatScheduleHour = (scheduleHourString) => {
    const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value));
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const fetchClassesWithStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        const responseClasses = await axios.get(`${API_URL}/classes/teacher/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        const responseStatus = await axios.get(`${API_URL}/classes/${userId}/classes-status`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        // Bypass filtering by date to include all classes
        const pastClasses = responseClasses.data; // No date filtering here
  
        const classesWithStatus = pastClasses.map(cls => {
          const statusClass = responseStatus.data.find(statusCls => statusCls.classid === cls.classid);
          return {
            ...cls,
            status: statusClass ? statusClass.status : 'pending',
            payout: statusClass ? statusClass.payout : 'pending'
          };
        });
  
  
        setClasses(classesWithStatus);
        setFilteredClasses(classesWithStatus);
      } catch (error) {
        console.error(t('errorFetchingClasses'), error);
      }
    };

    
  
    fetchClassesWithStatus();
  }, [userId, API_URL, t]);
  
  // Fetch Class Change Logs
  useEffect(() => {
    const fetchClassChangeLogs = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setClassChangeLogs(response.data);
      } catch (error) {
        console.error('Error fetching class change logs:', error);
      }
    };

    fetchClassChangeLogs();
  }, [API_URL]);

  // Show Toasty for any "cancelled_by_student"
  useEffect(() => {
    classChangeLogs.forEach(log => {
      if (log.changereason === 'cancelled_by_student') {
        toast.error(`Class ${log.class_code} was cancelled by the student.`, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          style: {
            backgroundColor: '#ffcccb',
            color: '#b71c1c',
            border: '2px solid #e57373',
            fontSize: '20px',
            padding: '20px',
          },
        });
      }
    });
  }, [classChangeLogs]);

  
  useEffect(() => {
    const applyFilters = () => {
      const filtered = classes.filter(cls => {
        const classDate = new Date(cls.date);
        const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
        const isMonthYearMatch = classMonthYear === selectedMonthYear;
        return isMonthYearMatch;
      });
  
      setFilteredClasses(filtered);
      setCurrentPage(1);
    };
   
    applyFilters();
  }, [selectedMonthYear, classes]); // Depend only on selectedMonthYear and classes
  
  const handleApprove = (classId, classDate) => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
  
    // Check if the class date is today or before today
    if (classDate > today) {
      // Show notification if the class date is in the future
      confirmAlert({
        title: t('Cannot Approve'),
        message: t('Class cannot be confirmed before the actual date of teaching'),
        buttons: [
          {
            label: t('ok'),
            onClick: () => {},
          },
        ],
      });
      return;
    }
  
    // If the class date is today or before, proceed with the usual confirmation
    confirmAlert({
      title: t('Confirm Approval'),
      message: t('Are you sure you want to approve this class?'),
      buttons: [
        {
          label: t('Yes'),
          onClick: () => handleSubmitApproval(classId),
        },
        {
          label: t('No'),
          onClick: () => {},
        },
      ],
    });
  };
  

  const handleSubmitApproval = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/classes/${classId}/approve`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      confirmAlert({
        title: t('Approval Successful'),
        message: t('Class approved successfully!'),
        buttons: [
          {
            label: t('OK'),
            onClick: () => {}
          }
        ]
      });

      setClasses(prevClasses =>
        prevClasses.map(cls =>
          cls.classid === classId ? { ...cls, status: 'approved' } : cls
        )
      );
    } catch (error) {
      confirmAlert({
        title: t('Approval Failed'),
        message: t('Failed to approve class. Please try again.'),
        buttons: [
          {
            label: t('OK'),
            onClick: () => {}
          }
        ]
      });
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(filteredClasses.length / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClasses = filteredClasses.slice(startIndex, endIndex);

  const renderTabs = () => (
    <div className="tutor-tabs-container">
      <button
        className={`tutor-tab-button ${activeTab === 'hours' ? 'active' : ''}`}
        onClick={() => setActiveTab('hours')}
      >
        {t('teacherHours')}
      </button>
      <button
        className={`tutor-tab-button ${activeTab === 'payment' ? 'active' : ''}`}
        onClick={() => setActiveTab('payment')}
      >
        {t('paymentReport')}
      </button>
    </div>
  );

  const monthYearOptions = generateMonthYearOptions();

  const getChangeReasonDisplay = (reason) => {
    switch (reason) {
        case 'admin_adjust':
            return { text: 'Admin', color: '#005181' };
        case 'cancelled_by_student':
            return { text: 'CL by S.', color: 'red' };
        case 'cancelled_by_teacher':
            return { text: 'CL by T.', color: 'orange' };
        case 'class_on_hold_by_student':
            return { text: 'On hold by S.', color: 'blue' };
        case 'class_on_hold_by_school':
            return { text: 'On hold by P.', color: 'green' };
        case 'others':
            return { text: 'Others', color: 'darkgrey' };
        default:
            return { text: reason, color: 'black' };
    }
};

  return (
    <div>
      <Sidebar />
      <Header />
      {renderTabs()}
      <div className="teacher-hours-container">
        {activeTab === 'hours' && (
          <div className="teacher-hours-content">
            <h1>{t('teacherHours')}</h1>

            <div className="filter-container">
            <select
              value={selectedMonthYear}
              onChange={(e) => setSelectedMonthYear(e.target.value)}
              className="filter-select"
            >
              {monthYearOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            <select
              value={payoutFilter}
              onChange={(e) => setPayoutFilter(e.target.value)}
              className="filter-select"
            >
              <option value="">{t('All Payouts')}</option>
              <option value="pending">{t('Pending')}</option>
              <option value="paid">{t('Paid')}</option>
            </select>

              <select
                value={payoutFilter}
                onChange={(e) => setPayoutFilter(e.target.value)}
                className="filter-select"
              >
                <option value="">{t('All Payouts')}</option>
                <option value="pending">{t('Pending')}</option>
                <option value="paid">{t('Paid')}</option>
              </select>
            </div>
            <table>
                    <thead>
                      <tr>
                        <th>{t('classcode')}</th>
                        <th>{t('classid')}</th>
                        <th>{t('subjectName')}</th>
                        <th>{t('date')}</th>
                        <th>{t('time')}</th>
                        <th>{t('scheduleHour')}</th>
                        <th>{t('approve')}</th>
                        <th>{t('payment')}</th>
                        <th>{t('classchange')}</th> {/* New column for change reason */}
                      </tr>
                    </thead>
                    <tbody>
                      {currentClasses.map(cls => {
                        const classDate = new Date(cls.date);
                        const today = new Date();

                        // Clear the time part to compare only the date
                        today.setHours(0, 0, 0, 0);
                        classDate.setHours(0, 0, 0, 0);

                        // Find the corresponding changelog for the class
                        const changeLog = classChangeLogs.find(log => log.classid === cls.classid);
                        const changeReason = changeLog ? changeLog.changereason : '';
                        const { text, color } = getChangeReasonDisplay(changeReason); // Use the helper function

                        return (
                          <tr key={cls.classid}>
                            <td>{cls.class_code}</td>
                            <td>{cls.classid}</td>
                            <td>{cls.subject_name}</td>
                            <td>{formatDate(cls.date)}</td>
                            <td>{formatTime(cls.schedule_time)}</td>
                            <td>{formatScheduleHour(cls.schedule_hour)} {t('hr')}</td>
                            <td>
                              {cls.status === 'approved' ? (
                                <span className="approved-text">{t('HOURSAPPROVED')}</span>
                              ) : (
                                <button onClick={() => handleApprove(cls.classid, cls.date)}>
                                  {t('confirmteach')}
                                </button>
                              )}
                            </td>
                            <td className={cls.payout === 'pending' ? 'payout-pending' : 'payout-paid'}>
                              {cls.payout === 'pending' ? t('Pending') : t('Paid')}
                            </td>
                            <td style={{ color }}> {/* Set the color from the display function */}
                              {text} {/* Set the text from the display function */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>



            {/* Pagination */}
            <div className="pagination-container">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="page-button"
              >
                {t('Previous')}
              </button>
              {Array.from({ length: Math.ceil(filteredClasses.length / itemsPerPage) }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredClasses.length / itemsPerPage)}
                className="page-button"
              >
                {t('Next')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRoleAccess(TeacherHours, ['teacher']);
