import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InventoryPage.css'; 
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { CSVLink } from 'react-csv'; // Import CSVLink for CSV export
import { useTranslation } from 'react-i18next';
import { Table, DatePicker, Button, Pagination, Space, Spin, notification } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const InventoryReport = () => {
    const { t } = useTranslation();
    const [reportData, setReportData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({ fromDate: null, toDate: null });
    const itemsPerPage = 50;

    const API_URL = process.env.REACT_APP_API_URL;

    const formatDateTime = (dateString) => {
        return moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    };

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/inventory/report`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const sortedData = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setReportData(sortedData);
                setFilteredData(sortedData);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching inventory report:', err);
                notification.error({
                    message: t('Error'),
                    description: t('Failed to fetch inventory report.')
                });
                setError('Failed to fetch inventory report.');
                setLoading(false);
            }
        };

        fetchReportData();
    }, [API_URL, t]);

    const handleDateChange = (dates) => {
        if (dates) {
            setFilters({
                fromDate: dates[0].startOf('day').toISOString(),
                toDate: dates[1].endOf('day').toISOString()
            });
        } else {
            setFilters({ fromDate: null, toDate: null });
        }
    };

    const applyFilters = () => {
        let filtered = reportData;
        if (filters.fromDate) {
            filtered = filtered.filter(item => new Date(item.timestamp) >= new Date(filters.fromDate));
        }
        if (filters.toDate) {
            filtered = filtered.filter(item => new Date(item.timestamp) <= new Date(filters.toDate));
        }
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const currentItems = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const csvData = filteredData.map(item => ({
        product_id: item.product_id,
        item_name: item.item_name,
        item_description: item.item_description,
        category: item.category,
        price_per_unit: item.price_per_unit,
        transaction_type: item.transaction_type,
        quantity_change: item.quantity_change,
        change_reason: item.change_reason,
        timestamp: formatDateTime(item.timestamp)
    }));

    if (loading) return <Spin tip={t('Loading...')} />;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <Sidebar />
            <Header />
            <h1>{t('Inventory Report')}</h1>

            <Space direction="vertical" style={{ marginBottom: '16px' }}>
                <RangePicker onChange={handleDateChange} />
                <Button type="primary" onClick={applyFilters}>{t('Apply Filters')}</Button>
                <CSVLink
                    data={csvData}
                    filename={"inventory_report.csv"}
                    className="btn btn-success"
                    target="_blank"
                >
                    <Button>{t('Export CSV')}</Button>
                </CSVLink>
            </Space>

            <Table
                dataSource={currentItems}
                columns={[
                    { title: t('productid'), dataIndex: 'product_id', key: 'product_id' },
                    { title: t('itemname'), dataIndex: 'item_name', key: 'item_name' },
                    { title: t('description'), dataIndex: 'item_description', key: 'item_description' },
                    { title: t('category'), dataIndex: 'category', key: 'category' },
                    { title: t('priceperunit'), dataIndex: 'price_per_unit', key: 'price_per_unit' },
                    { title: t('type'), dataIndex: 'transaction_type', key: 'transaction_type' },
                    { title: t('unit'), dataIndex: 'quantity_change', key: 'quantity_change' },
                    { title: t('changereason'), dataIndex: 'change_reason', key: 'change_reason' },
                    { title: t('document'), dataIndex: 'document_no', key: 'document_no', render: (text, record) => text || record.invoiceno },
                    { title: t('date'), dataIndex: 'timestamp', key: 'timestamp', render: formatDateTime }
                ]}
                pagination={{
                    current: currentPage,
                    pageSize: itemsPerPage,
                    total: filteredData.length,
                    onChange: (page) => setCurrentPage(page),
                    showSizeChanger: false
                }}
                rowKey="timestamp"
            />
        </div>
    );
};

export default withRoleAccess(InventoryReport, ['superadmin', 'admin']);
