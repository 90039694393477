import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { Layout, Table, Modal, Button, Input, Select, DatePicker, Space, Pagination } from 'antd';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Registrations.css';

const { Content } = Layout;
const { Option } = Select;

// Main test types and their associated subtypes
const testTypes = [
  { name: "GED", subtypes: ["Mathematical Reasoning", "Reasoning Through Language Arts", "Science", "Social Studies"] },
  { name: "IGCSE", subtypes: ["Mathematics", "English", "Sciences (Biology, Chemistry, Physics)", "History", "Geography", "Foreign Languages", "Art & Design", "Business Studies", "Computer Science"] },
  { name: "A-LEVEL", subtypes: ["Mathematics", "Further Mathematics", "Physics", "Chemistry", "Biology", "English Literature", "History", "Geography", "Economics", "Psychology"] },
  { name: "SAT", subtypes: ["SAT (General)", "SAT Subject Tests"] },
  { name: "IELTS", subtypes: ["IELTS Academic", "IELTS General Training"] },
  { name: "AP", subtypes: ["AP Calculus AB", "AP Calculus BC", "AP Physics", "AP Chemistry", "AP Biology", "AP English Language and Composition", "AP English Literature and Composition", "AP U.S. History", "AP World History", "AP Psychology"] },
  { name: "IB", subtypes: ["IB Mathematics", "IB Physics", "IB Chemistry", "IB Biology", "IB English A", "IB English B", "IB History", "IB Economics", "IB Psychology", "Theory of Knowledge (TOK)"] },
  { name: "TOEFL", subtypes: ["TOEFL iBT", "TOEFL PBT"] },
  { name: "TOEFL-MUIC", subtypes: ["General", "IBT"] },
  { name: "CU-AAT", subtypes: ["ธรรมดา", "E-Testing"] },
  { name: "CU-ATS", subtypes: ["Physics", "Chemistry"] },
  { name: "CU-TEP", subtypes: ["ธรรมดา + Speaking", "E-Testing", "E-Testing + CU-TEP E-Testing Speaking"] },
  { name: "TU-GET", subtypes: ["PBT", "CBT"] },
  { name: "OTHERS", subtypes: [] }
];

const TestScore = () => {
  const { i18n, t } = useTranslation();
  const [testScores, setTestScores] = useState([]);
  const [students, setStudents] = useState([]);
  const [formData, setFormData] = useState({
    test_date: '',
    test_name: '',
    score: '',
    type: '',
    sub_type: '',
    student_id: '',
    note: ''
  });
  const [error, setError] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const fetchTestScores = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/test-scores`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTestScores(response.data);
    } catch (error) {
      console.error('Error fetching test scores:', error);
    }
  }, [API_URL]);

  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudents(response.data.students);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchTestScores();
    fetchStudents();
  }, [fetchTestScores, fetchStudents]);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/test-scores`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFormData({
        test_date: '',
        test_name: '',
        score: '',
        type: '',
        sub_type: '',
        student_id: '',
        note: ''
      });
      setIsModalVisible(false);
      fetchTestScores();
    } catch (error) {
      console.error('Error submitting test score:', error);
      setError(t('Error submitting test score'));
    }
  };

  const handleFormChange = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const getSubtypes = () => {
    const selectedTestType = testTypes.find((type) => type.name === formData.type);
    return selectedTestType ? selectedTestType.subtypes : [];
  };

  const columns = [
    { title: t('Student'), dataIndex: 'firstname', key: 'firstname', render: (_, record) => `${record.firstname} ${record.lastname}` },
    { title: t('Nickname'), dataIndex: 'nickname', key: 'nickname' },
    { title: t('Test Date'), dataIndex: 'test_date', key: 'test_date', render: (date) => formatDate(date) },
    { title: t('Type'), dataIndex: 'type', key: 'type' },
    { title: t('Subject'), dataIndex: 'sub_type', key: 'sub_type' },
    { title: t('Details'), dataIndex: 'test_name', key: 'test_name' },
    { title: t('Score'), dataIndex: 'score', key: 'score' },
    { title: t('Note'), dataIndex: 'note', key: 'note' },
  ];

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            {t('Add Student Score')}
          </Button>
          <Modal
            title={t('Add Student Score')}
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={handleSubmit}
          >
            <div className="form-group">
              <label>{t('Student')}</label>
              <Select
                value={formData.student_id}
                onChange={(value) => handleFormChange('student_id', value)}
                placeholder={t('Select Student')}
                style={{ width: '100%' }}
              >
                {students.map(student => (
                  <Option key={student.userid} value={student.userid}>
                    {`${student.firstname} ${student.lastname} (${student.nickname})`}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="form-group">
              <label>{t('Test Date')}</label>
              <DatePicker
                value={formData.test_date ? new Date(formData.test_date) : null}
                onChange={(date) => handleFormChange('test_date', date?.toISOString())}
                style={{ width: '100%' }}
              />
            </div>
            <div className="form-group">
              <label>{t('Test Type')}</label>
              <Select
                value={formData.type}
                onChange={(value) => handleFormChange('type', value)}
                placeholder={t('Select Test Type')}
                style={{ width: '100%' }}
              >
                {testTypes.map((type) => (
                  <Option key={type.name} value={type.name}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </div>
            {formData.type && (
              <div className="form-group">
                <label>{t('Subject')}</label>
                <Select
                  value={formData.sub_type}
                  onChange={(value) => handleFormChange('sub_type', value)}
                  placeholder={t('Select Subject')}
                  style={{ width: '100%' }}
                >
                  {getSubtypes().map((subtype, index) => (
                    <Option key={index} value={subtype}>
                      {subtype}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            <div className="form-group">
              <label>{t('Details')}</label>
              <Input
                value={formData.test_name}
                onChange={(e) => handleFormChange('test_name', e.target.value)}
                placeholder={t('Enter test details')}
              />
            </div>
            <div className="form-group">
              <label>{t('Score')}</label>
              <Input
                value={formData.score}
                onChange={(e) => handleFormChange('score', e.target.value)}
                placeholder={t('Enter score')}
              />
            </div>
            <div className="form-group">
              <label>{t('Note')}</label>
              <Input.TextArea
                value={formData.note}
                onChange={(e) => handleFormChange('note', e.target.value)}
                rows={4}
                placeholder={t('Enter any additional notes')}
              />
            </div>
            {error && <div className="error-message">{error}</div>}
          </Modal>

          <h2>{t('All Test Scores')}</h2>
          <Table
            columns={columns}
            dataSource={testScores}
            pagination={{
              current: currentPage,
              pageSize: itemsPerPage,
              total: testScores.length,
              onChange: setCurrentPage,
            }}
            rowKey="id"
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(TestScore, ['admin', 'superadmin']);
