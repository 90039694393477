import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Card, Tooltip, Spin } from 'antd';
import { UserOutlined, TeamOutlined, BookOutlined } from '@ant-design/icons';
import withRoleAccess from '../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import {
  BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, PieChart, Pie, Cell,
} from 'recharts';
import './Dashboard.css';

function AdminDashboard() {
  const { t, i18n } = useTranslation();
  const [stats, setStats] = useState([]);
  const [todayClasses, setTodayClasses] = useState([]);
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const [studentsResponse, teachersResponse, classesResponse] = await Promise.all([
          axios.get(`${API_URL}/students/count`, { headers }),
          axios.get(`${API_URL}/teachers/count`, { headers }),
          axios.get(`${API_URL}/class-groups/count`, { headers }),
        ]);

        setStats({
          totalStudents: studentsResponse.data.total_students,
          totalTeachers: teachersResponse.data.total_teachers,
          totalClasses: classesResponse.data.total_classes,
          totalSales: 140000, // Replace with real sales data if available
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError(t('fetchStatsError'));
      }
    };

    const fetchTodayClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`${API_URL}/classes`, { headers });
        const today = new Date().toISOString().split('T')[0];

        const todayClasses = response.data.filter(cls => new Date(cls.date).toISOString().split('T')[0] === today);
        setTodayClasses(todayClasses);
      } catch (error) {
        console.error("Error fetching today's classes:", error);
        setError(t('fetchTodayClassesError'));
      }
    };

    const fetchClassGroups = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`${API_URL}/class-groups`, { headers });
        setClassGroups(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching class groups:', error);
        setError(t('fetchClassGroupsError'));
        setLoading(false);
      }
    };

    fetchStats();
    fetchTodayClasses();
    fetchClassGroups();
  }, [API_URL, t]);

  if (loading) return <Spin tip={t('loading')} />;
  if (error) return <p>{error}</p>;

  return (
    <div className="admin-dashboard-container">
      <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
        <Col xs={24} sm={12} md={8}>
          <Card className="stat-card" bordered>
            <UserOutlined className="icon-circle" />
            <h1>{t('totalStudents')}</h1>
            <h1>{stats.totalStudents}</h1>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card className="stat-card" bordered>
            <TeamOutlined className="icon-circle" />
            <h1>{t('totalTeachers')}</h1>
            <h1>{stats.totalTeachers}</h1>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card className="stat-card" bordered>
            <BookOutlined className="icon-circle" />
            <h1>{t('activeClasses')}</h1>
            <h1>{stats.totalClasses}</h1>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <Card title={t('GED vs IELTS Student Count')}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={[{ subject: 'GED', students: 120 }, { subject: 'IELTS', students: 75 }]}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="subject" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="students" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <Card title={t('SAT Performance by Subject')}>
            <ResponsiveContainer width="100%" height={300}>
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={[{ subject: 'Reading', score: 650 }, { subject: 'Math', score: 700 }, { subject: 'Writing', score: 640 }]}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis />
                <Radar dataKey="score" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                <Tooltip />
              </RadarChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <Card title={t('IGCSE Grade Distribution')}>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie data={[{ grade: 'A*', students: 40 }, { grade: 'A', students: 30 }, { grade: 'B', students: 20 }, { grade: 'C', students: 10 }]} dataKey="students" cx="50%" cy="50%" outerRadius={80}>
                  {COLORS.map((color, index) => (
                    <Cell key={`cell-${index}`} fill={color} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default withRoleAccess(AdminDashboard, ['admin', 'superadmin']);
