import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './InventoryPage.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { Modal, Table, Input, Button, Form, Select, notification, Spin, Layout } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Content } = Layout;

const CurrentInventory = () => {
  const { t } = useTranslation();
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [adjustments, setAdjustments] = useState({});
  const [selectedInventoryId, setSelectedInventoryId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editInventoryData, setEditInventoryData] = useState({});
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchInventory = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const [inventoryResponse, quantityResponse] = await Promise.all([
        axios.get(`${API_URL}/inventory`, { headers }),
        axios.get(`${API_URL}/inventory/current-quantity`, { headers }),
      ]);

      const inventoryWithQuantities = inventoryResponse.data.map((item) => {
        const quantityData = quantityResponse.data.find(q => q.inventory_id === item.inventory_id);
        return {
          ...item,
          current_quantity: quantityData ? quantityData.current_quantity : 0,
        };
      });

      setInventory(inventoryWithQuantities);
      setLoading(false);
    } catch (err) {
      setError(t('errorFetchingInventory'));
      setLoading(false);
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchInventory();
  }, [fetchInventory]);

  const handleUpdateQuantity = async () => {
    const quantity_change = adjustments[selectedInventoryId];
    if (!quantity_change) {
      notification.warning({ message: t('enterQuantityWarning') });
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/inventory/log`,
        {
          inventory_id: selectedInventoryId,
          quantity_change: parseInt(quantity_change, 10),
          change_reason: 'Admin',
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsModalOpen(false);
      fetchInventory();
      notification.success({ message: t('inventoryUpdatedSuccess') });
    } catch (error) {
      console.error('Error adjusting quantity:', error);
      notification.error({ message: t('inventoryUpdateError') });
    }
  };

  const openModal = (id) => {
    setSelectedInventoryId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedInventoryId(null);
    setIsModalOpen(false);
  };

  const openEditModal = (item) => {
    setSelectedInventoryId(item.inventory_id);
    setEditInventoryData(item);
    editForm.setFieldsValue(item);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedInventoryId(null);
    setEditInventoryData({});
    setIsEditModalOpen(false);
  };

  const handleEditInventorySubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const updatedData = editForm.getFieldsValue();
      await axios.put(`${API_URL}/inventory/${selectedInventoryId}`, updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setIsEditModalOpen(false);
      fetchInventory();
      notification.success({ message: t('inventoryItemUpdated') });
    } catch (error) {
      console.error('Error updating inventory:', error);
      notification.error({ message: t('inventoryUpdateError') });
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_URL}/inventory/${id}/status`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchInventory();
      notification.success({ message: t('statusUpdated') });
    } catch (error) {
      console.error('Error changing status:', error);
      notification.error({ message: t('statusUpdateError') });
    }
  };

  if (loading) return <Spin tip={t('loading')} />;
  if (error) return <p>{error}</p>;

  const columns = [
    { title: t('productid'), dataIndex: 'product_id', key: 'product_id' },
    { title: t('itemname'), dataIndex: 'item_name', key: 'item_name' },
    { title: t('description'), dataIndex: 'item_description', key: 'item_description' },
    { title: t('category'), dataIndex: 'category', key: 'category' },
    { title: t('priceperunit'), dataIndex: 'price_per_unit', key: 'price_per_unit' },
    { title: t('currentquantity'), dataIndex: 'current_quantity', key: 'current_quantity' },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status}
          onChange={(value) => handleStatusChange(record.inventory_id, value)}
        >
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      ),
    },
    {
      title: t('adjust'),
      key: 'adjust',
      render: (_, record) => (
        <Button onClick={() => openModal(record.inventory_id)}>{t('adjustInventory')}</Button>
      ),
    },
    {
      title: t('edit'),
      key: 'edit',
      render: (_, record) => (
        <Button icon={<EditOutlined />} onClick={() => openEditModal(record)} />
      ),
    },
  ]; 

  return (
    <div className="">
      <Sidebar />
      <Header />
      <Content style={{ padding: '10px' }}>
      <h1>{t('Current Inventory')}</h1>
      <Table dataSource={inventory} columns={columns} rowKey="inventory_id" />

      {/* Adjust Quantity Modal */}
      <Modal
        visible={isModalOpen}
        onCancel={closeModal}
        onOk={handleUpdateQuantity}
        title={t('adjustInventoryQuantity')}
      >
        <Input
          type="number"
          value={adjustments[selectedInventoryId] || ''}
          onChange={(e) => setAdjustments({ ...adjustments, [selectedInventoryId]: e.target.value })}
          placeholder={t('enterQuantity')}
        />
      </Modal>

      {/* Edit Inventory Modal */}
      <Modal
        visible={isEditModalOpen}
        onCancel={closeEditModal}
        onOk={handleEditInventorySubmit}
        title={t('editInventoryItem')}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item name="product_id" label={t('productid')}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="item_name" label={t('itemname')}>
            <Input />
          </Form.Item>
          <Form.Item name="item_description" label={t('description')}>
            <Input />
          </Form.Item>
          <Form.Item name="category" label={t('category')}>
            <Input />
          </Form.Item>
          <Form.Item name="price_per_unit" label={t('priceperunit')}>
            <Input disabled />
          </Form.Item>
        </Form>
      </Modal>
      </Content>
    </div>
  );
};

export default withRoleAccess(CurrentInventory, ['superadmin', 'admin']);
