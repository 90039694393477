import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Input, Button, Table, Pagination, Tabs, notification } from 'antd';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import './TeacherHours.css';
import TeacherPaymentReport from './TeacherPaymentReport';
import TutorClassLogPayment from './TutorClassLogPayment';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;
const { TabPane } = Tabs;

const TeacherHoursAdmin = () => {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const [activeTab, setActiveTab] = useState('hours');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTeachers(response.data);
      } catch (error) {
        console.error(t('errorFetchingTeachers'), error);
        setError(t('failedToFetchTeachers'));
        notification.error({ message: t('failedToFetchTeachers') });
      }
    };

    fetchTeachers();
  }, [API_URL, t]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredTeachers = teachers.filter(
    (teacher) =>
      teacher.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.userid.toString().includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredTeachers.length / itemsPerPage);
  const currentTeachers = filteredTeachers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ marginTop: '60px', marginLeft: '70px', padding: '20px' }}>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab={t('teacherHours')} key="hours">
              <div style={{ marginBottom: '20px' }}>
                <Input
                  placeholder={t('searchByNameOrID')}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{ width: '300px', marginBottom: '20px' }}
                />
              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <Table
                dataSource={currentTeachers}
                columns={[
                  {
                    title: t('teacherID'),
                    dataIndex: 'userid',
                    key: 'userid',
                  },
                  {
                    title: t('teacherName'),
                    dataIndex: 'firstname',
                    key: 'firstname',
                    render: (text, record) => (
                      <Link to={`/teacher-details/${record.userid}`}>
                        ({record.nickname}) {record.firstname} {record.lastname}
                      </Link>
                    ),
                  },
                  {
                    title: t('hourlyRate'),
                    dataIndex: 'hoursrate',
                    key: 'hoursrate',
                  },
                ]}
                pagination={false}
                rowKey="userid"
              />
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={filteredTeachers.length}
                onChange={handlePageChange}
                style={{ marginTop: '20px', textAlign: 'center' }}
              />
            </TabPane>
            <TabPane tab={t('paymentReport')} key="payment">
              <TeacherPaymentReport />
            </TabPane>
            <TabPane tab={t('tutorclasslogs')} key="logs">
              <TutorClassLogPayment />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(TeacherHoursAdmin, ['superadmin']);
