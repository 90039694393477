import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { sarabunFont } from '../../sarabunFont';
import './Classes.css';

// Function to load the Sarabun font into jsPDF
const loadSarabunFont = (doc) => {
  doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
  doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
  doc.setFont('Sarabun');
};

const CurrentClasses = () => {
  const { t, i18n } = useTranslation();
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [registrationsCount, setRegistrationsCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 30;
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return 'Invalid Date';
    }
  };

  const formatTime = (timeString) => {
    try {
      const [hours, minutes] = timeString.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    } catch (error) {
      console.error('Invalid time value:', timeString);
      return 'Invalid Time';
    }
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hrs')}`;
  };

  const formatScheduleHour = (scheduleHourString) => {
    try {
      const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value, 10));
      return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hrs')}`;
    } catch (error) {
      console.error('Invalid schedule hour value:', scheduleHourString);
      return 'Invalid Time';
    }
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = t('daysOfWeek', { returnObjects: true });
    return daysOfWeek[date.getDay()];
  };

  const fetchClassGroups = useCallback(async () => {
    try {
  
      const token = localStorage.getItem('token');
  
      const response = await axios.get(`${API_URL}/class-groups`, { 
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
  
      setClassGroups(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching class groups:', error); // Log the error if it occurs
      setError(t('fetchClassGroupsError'));
  
      // Log the full error response if available
      if (error.response) {
        console.error('Error Response Data:', error.response.data);
        console.error('Error Status:', error.response.status);
      } else {
        console.error('Error Message:', error.message);
      }
  
      setLoading(false);
    }
  }, [API_URL, t]);
  

  const fetchRegistrationsCount = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setRegistrationsCount(response.data);
    } catch (error) {
      console.error('Error fetching registrations count:', error);
      setError(t('fetchRegistrationsCountError'));
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchClassGroups();
    fetchRegistrationsCount();
  }, [fetchClassGroups, fetchRegistrationsCount]);

  const handleScheduleClick = (schedule) => {
    const sortedSchedule = [...schedule].sort((a, b) => new Date(b.date) - new Date(a.date));
    setPopupData(sortedSchedule);
  };

  const closePopup = () => {
    setPopupData(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  // Get current date
  const currentDate = new Date();

  // Sort and filter classes: sort by start date and filter out past classes
  const sortedClassGroups = classGroups
  .filter(cls => cls.isprivate === false && new Date(cls.end_date) >= currentDate) // Only non-private classes
  .sort((a, b) => new Date(a.start_date) - new Date(b.start_date)); // Sort by start date ascending

  // Filter classes based on search term
  const filteredClassGroups = sortedClassGroups.filter(cls =>
    cls.class_code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredClassGroups.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClassGroups = filteredClassGroups.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPagination = () => {
    const pages = [];

    pages.push(
      <button
        key="prev"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="page-button"
      >
        {t('prev')}
      </button>
    );

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`page-button ${i === currentPage ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    pages.push(
      <button
        key="next"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="page-button"
      >
        {t('next')}
      </button>
    );

    return pages;
  };

  const getRegisteredStudents = (groupId) => {
    const group = registrationsCount.find(rc => rc.groupid === groupId);
    return group ? group.registered_students : 0;
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    loadSarabunFont(doc);
  
    const margin = 20;
    const startY = margin + 60;
  
    // Load and add the header image
    const headerImage = new Image();
    headerImage.src = '/images/Theplanner.png'; // Adjust the path as needed
  
    headerImage.onload = () => {
      // Add header image (40x40 px)
      doc.addImage(headerImage, 'PNG', margin, margin, 35, 30);
  
      // Add header text
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('THE PLANNER EDUCATION', margin + 50, margin + 10);
  
      doc.setFont('helvetica', 'normal');
      doc.text('The Mercury Ville @Chidlom 3rd floor', margin + 50, margin + 15);
      doc.text('Lumpini Pathumwan Bangkok', margin + 50, margin + 20);
      doc.text('www.theplannereducation.com', margin + 50, margin + 25);
      doc.text('Tel. 02-253-2533 or 095-726-2666 Line: @theplanner', margin + 50, margin + 30);
  
      // Prepare class details data
      const classCode = popupData[0]?.class_code || 'N/A'; // Use popupData to get the class_code
      const totalHours = popupData?.[0]?.total_hours || 0;
      const subjects = [...new Set(popupData.map(slot => slot.subject_name))]; // Get unique subjects
      const subjectsText = subjects.join(', ');
  
      // Calculate center of the page
      const pageWidth = doc.internal.pageSize.getWidth();
      const centerX = pageWidth / 2;
  
      // Add class details text centered on the page
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(classCode, centerX, startY - 15, { align: 'center' }); // Centered class_code
      doc.text(`Total Hours: ${formatMinutesToHours(totalHours)}`, centerX, startY - 10, { align: 'center' });
      doc.text(`Subjects: ${subjectsText}`, centerX, startY - 5, { align: 'center' }); // Centered subjects
  
      // Sort popupData by date and time before preparing table rows
      const sortedPopupData = popupData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
  
        if (dateA.getTime() === dateB.getTime()) {
          // If dates are the same, sort by schedule_time
          return a.schedule_time.localeCompare(b.schedule_time);
        }
        return dateA - dateB;
      });
  
      // Prepare table data
      const columns = [
        { header: 'SUBJECT', dataKey: 'subject_name' },
        { header: 'DAY', dataKey: 'schedule_day' },
        { header: 'DATE', dataKey: 'date' },
        { header: 'TIME', dataKey: 'schedule_time' },
        { header: 'HOURS', dataKey: 'schedule_hour' },
      ];
  
      const rows = sortedPopupData.map(slot => ({
        subject_name: slot.subject_name,
        schedule_day: getDayOfWeek(slot.date),
        date: formatDate(slot.date),
        schedule_time: `${formatTime(slot.schedule_time)} - ${formatTime(slot.end_time)}`,
        schedule_hour: formatScheduleHour(slot.schedule_hour),
      }));
  
      // Add table below the header and class details with a custom header color
      doc.autoTable({
        startY: startY + 5,
        head: [columns.map(col => col.header)],
        body: rows.map(row => columns.map(col => row[col.dataKey])),
        theme: 'grid',
        styles: {
          font: 'Sarabun',
          fontStyle: 'normal'
        },
        headStyles: {
          fillColor: '#005082', // Set the header background color
          textColor: '#FFFFFF' // Set the text color for the header
        }
      });
  
      // Save the PDF with the classCode as the file name
      doc.save(`${classCode}.pdf`);
    };
  };
  


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="cc-class-container">
        <div className="cc-class-header">
          <h1>{t('ourCourses')}</h1>        
          
          </div>
          <div className="cc-search-container">
            <input
              type="text"
              placeholder={t('searchByClassCode')}
              value={searchTerm}
              onChange={handleSearchChange}
              className="cc-search-input"
            />
          </div>

          <div className="pagination-container">
            {renderPagination()}
          </div>

        <div className="cc-list">
          {currentClassGroups.map((cls, index) => (
            <div key={index} className="cc-item">
              <div className={`cc-icon cc-icon-${index % 3}`}>
                <span>{cls.class_code.charAt(0)}</span>
              </div>
              <div className="cc-content">
                <h3 className="cc-title">
                  {cls.class_code}
                </h3>
                <p className="cc-subtitle">{formatDate(cls.start_date)} - {formatDate(cls.end_date)}</p>
                <p>{t('totalHours')} : {formatMinutesToHours(cls.total_hours)}</p>
                <p>{t('maxRegister')} : {cls.register_capacity}</p>
                <p>{t('totalRegister')} : {getRegisteredStudents(cls.groupid)}</p>
                <p>{t('type')} : {cls.isprivate ? t('private') : t('group')}</p>
                <button onClick={() => handleScheduleClick(cls.classes)} className="cc-schedule-button">
                  {t('viewSchedule')}
                </button>
              </div>
            </div>
          ))}
        </div>

        <Modal isOpen={!!popupData} onClose={closePopup}>
        <h2>{popupData?.[0]?.class_code || t('scheduleDetails')}</h2> 
          <button onClick={handleDownloadPDF} className="print-button">
          <FontAwesomeIcon icon={faDownload} /> {t('downloadPDF')}
          </button>
          <table className="cc-schedule-table">
            <thead>
              <tr>
                <th>{t('subjectName')}</th>
                <th>{t('day')}</th>
                <th>{t('date')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
              </tr>
            </thead>
            <tbody>
            {popupData && popupData
              .sort((a, b) => {
                // First, compare by date
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                
                if (dateA.getTime() !== dateB.getTime()) {
                  // If the dates are different, sort by date
                  return dateA - dateB;
                } else {
                  // If the dates are the same, sort by schedule_time (assuming it's in 'HH:mm' format)
                  const timeA = a.schedule_time ? a.schedule_time.split(':').map(Number) : [0, 0]; // Default to 00:00 if time is missing
                  const timeB = b.schedule_time ? b.schedule_time.split(':').map(Number) : [0, 0];

                  const hoursDiff = timeA[0] - timeB[0];
                  const minutesDiff = timeA[1] - timeB[1];

                  return hoursDiff !== 0 ? hoursDiff : minutesDiff;
                }
              })
              .map((slot, index) => (
                <tr key={index}>
                  <td>{slot.subject_name}</td>
                  <td>{getDayOfWeek(slot.date)}</td>
                  <td>{formatDate(slot.date)}</td>
                  <td>{formatTime(slot.schedule_time)} - {formatTime(slot.end_time)}</td>
                  <td>{formatScheduleHour(slot.schedule_hour)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      </div>
    </div>
  );
};

export default withRoleAccess(CurrentClasses, ['admin', 'superadmin', 'teacher', 'student']);