import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Table, Pagination, Tag } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';

const ClassChange = () => {
  const { i18n, t } = useTranslation();
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 100; // Number of items per page
  const API_URL = process.env.REACT_APP_API_URL;

  const formatTime = (timeString) => {
    try {
      const [hours, minutes] = timeString.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    } catch (error) {
      console.error('Invalid time value:', timeString);
      return t('invalidTime');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date);
  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat(i18n.language, options).format(dateTime);
  };

  const calculateDuration = (startTime, endTime) => {
    try {
      const start = new Date(`1970-01-01T${startTime}Z`);
      const end = new Date(`1970-01-01T${endTime}Z`);
      const diff = (end - start) / (1000 * 60); // difference in minutes

      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;

      return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hr')}`;
    } catch (error) {
      console.error('Error calculating duration:', error);
      return t('invalidDuration');
    }
  };

  const getChangeReasonDisplay = (reason) => {
    switch (reason) {
      case 'admin_adjust':
        return { text: 'Admin', color: 'blue' };
      case 'cancelled_by_student':
        return { text: 'CL by S.', color: 'red' };
      case 'cancelled_by_teacher':
        return { text: 'CL by T.', color: 'orange' };
      case 'class_on_hold_by_student':
        return { text: 'On hold by S.', color: 'blue' };
      case 'class_on_hold_by_school':
        return { text: 'On hold by P.', color: 'green' };
      case 'others':
        return { text: 'Others', color: 'darkgrey' };
      default:
        return { text: reason, color: 'black' };
    }
  };

  const fetchClassChangeLogs = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setClassChangeLogs(response.data);
    } catch (error) {
      console.error('Error fetching class change logs:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchClassChangeLogs();
  }, [fetchClassChangeLogs]);

  const columns = [
    { title: t('classcode'), dataIndex: 'class_code', key: 'class_code' },
    { title: t('id'), dataIndex: 'classid', key: 'classid' },
    { title: t('subject'), dataIndex: 'subject_name', key: 'subject_name' },
    { title: t('teacher'), dataIndex: 'nickname', key: 'nickname' },
    { title: t('originaldate'), dataIndex: 'originaldate', key: 'originaldate', render: formatDate },
    {
      title: t('originaltime'),
      key: 'originaltime',
      render: (text, record) => `${formatTime(record.originalstarttime)} - ${formatTime(record.originalendtime)}`
    },
    {
      title: t('originalhour'),
      key: 'originalhour',
      render: (text, record) => calculateDuration(record.originalstarttime, record.originalendtime)
    },
    { title: t('newdate'), dataIndex: 'newdate', key: 'newdate', render: formatDate },
    {
      title: t('newtime'),
      key: 'newtime',
      render: (text, record) => `${formatTime(record.newstarttime)} - ${formatTime(record.newendtime)}`
    },
    {
      title: t('newhour'),
      key: 'newhour',
      render: (text, record) => calculateDuration(record.newstarttime, record.newendtime)
    },
    {
      title: t('changereason'),
      dataIndex: 'changereason',
      key: 'changereason',
      render: (reason) => {
        const { text, color } = getChangeReasonDisplay(reason);
        return <Tag color={color}>{text}</Tag>;
      }
    },
    { title: t('note'), dataIndex: 'note', key: 'note' },
    { title: t('admin'), dataIndex: 'updateby', key: 'updateby' },
    { title: t('updateat'), dataIndex: 'updated_at', key: 'updated_at', render: formatDateTime }
  ];

  return (
    <div>
      <Table
        dataSource={classChangeLogs}
        columns={columns}
        rowKey="id"
        pagination={{
          current: currentPage,
          pageSize: rowsPerPage,
          total: classChangeLogs.length,
          onChange: (page) => setCurrentPage(page),
        }}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default withRoleAccess(ClassChange, ['admin', 'superadmin']);
