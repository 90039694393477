import React, { createContext, useContext, useState } from 'react';
import { jwtDecode } from 'jwt-decode'; // Correct import

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [permissions, setPermissions] = useState({});

  const signIn = (token) => {
    try {
      // Ensure the token is a valid string
      if (typeof token !== 'string' || token.trim() === '') {
        throw new Error('Invalid token specified: must be a non-empty string');
      }

      // Decode the token
      const decodedToken = jwtDecode(token);

      
      const { role, ...rest } = decodedToken;
 

      // Set authentication state
      setIsAuthenticated(true);
      setUser(rest);
      setPermissions({ role });

      // Store the token in local storage for persistence
      localStorage.setItem('token', token);

    } catch (error) {
      console.error('Failed to decode token:', error);
      signOut();
    }
  };

  const signOut = () => {
    // Clear authentication state
    setIsAuthenticated(false);
    setUser(null);
    setPermissions({});

    // Remove the token from local storage
    localStorage.removeItem('token');
  };

  const value = {
    user,
    isAuthenticated,
    permissions,
    signIn,
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
