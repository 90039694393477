import React from 'react';
import { Modal, Button, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { sarabunFont } from '../../sarabunFont'; // Import your font data
import { useTranslation } from 'react-i18next';

const ScheduleModal = ({ isVisible, onClose, popupData }) => {
  const { t, i18n } = useTranslation();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date);
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  // Function to load Sarabun font into jsPDF
  const loadSarabunFont = (doc) => {
    doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
    doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
    doc.setFont('Sarabun');
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    loadSarabunFont(doc);

    const margin = 20;
    const startY = margin + 60;

    const headerImage = new Image();
    headerImage.src = '/images/plann-p.png';

    headerImage.onload = () => {
      doc.addImage(headerImage, 'PNG', margin, margin, 35, 30);

      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('PLANN TECH', margin + 50, margin + 10);
      doc.setFont('helvetica', 'normal');
      doc.text('ADDRESS, BANGKOK', margin + 50, margin + 15);
      doc.text('ADDRESS, BANGKOK', margin + 50, margin + 20);
      doc.text('plann.tech', margin + 50, margin + 25);
      doc.text('info@plann.tech', margin + 50, margin + 30);

      const classCode = popupData[0]?.class_code || 'N/A';
      const totalHours = popupData?.[0]?.total_hours || 0;
      const subjects = [...new Set(popupData.map(slot => slot.subject_name))].join(', ');

      const pageWidth = doc.internal.pageSize.getWidth();
      const centerX = pageWidth / 2;

      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(classCode, centerX, startY - 15, { align: 'center' });
      doc.text(`Total Hours: ${totalHours} ${t('hrs')}`, centerX, startY - 10, { align: 'center' });
      doc.text(`Subject(s): ${subjects}`, centerX, startY - 5, { align: 'center' });

      const sortedPopupData = popupData.sort((a, b) => new Date(a.date) - new Date(b.date));
      const columns = [
        { header: t('subjectName'), dataKey: 'subject_name' },
        { header: t('teacherName'), dataKey: 'nickname' },
        { header: t('day'), dataKey: 'schedule_day' },
        { header: t('date'), dataKey: 'date', render: formatDate },
        {
          header: t('time'),
          dataKey: 'schedule_time',
          render: (text, record) => `${formatTime(record.schedule_time)} - ${formatTime(record.end_time)}`
        },
        { header: t('hours'), dataKey: 'schedule_hour' },
        { header: t('classroomNumber'), dataKey: 'classroom_number' }
      ];

      const rows = sortedPopupData.map(slot => ({
        subject_name: slot.subject_name,
        nickname: slot.nickname,
        schedule_day: slot.schedule_day,
        date: slot.date,
        schedule_time: slot.schedule_time,
        end_time: slot.end_time,
        schedule_hour: slot.schedule_hour,
        classroom_number: slot.classroom_number
      }));

      doc.autoTable({
        startY: startY + 5,
        head: [columns.map(col => col.header)],
        body: rows.map(row => columns.map(col => row[col.dataKey])),
        theme: 'grid',
        styles: { font: 'Sarabun', fontStyle: 'normal' },
        headStyles: { fillColor: '#005082', textColor: '#FFFFFF' }
      });

      doc.save(`${classCode}.pdf`);
    };

    headerImage.onerror = () => {
      console.error('Failed to load the header image.');
    };
  };

  const columns = [
    { title: t('subjectName'), dataIndex: 'subject_name', key: 'subject_name' },
    { title: t('teacherName'), dataIndex: 'nickname', key: 'nickname' },
    { title: t('day'), dataIndex: 'schedule_day', key: 'schedule_day' },
    { title: t('date'), dataIndex: 'date', key: 'date', render: formatDate },
    {
      title: t('time'),
      key: 'time',
      render: (text, record) => `${formatTime(record.schedule_time)} - ${formatTime(record.end_time)}`
    },
    { title: t('hours'), dataIndex: 'schedule_hour', key: 'schedule_hour' },
    { title: t('classroomNumber'), dataIndex: 'classroom_number', key: 'classroom_number' }
  ];

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="download" onClick={handleDownloadPDF}>
          <FontAwesomeIcon icon={faDownload} /> {t('downloadPDF')}
        </Button>
      ]}
      title={popupData?.[0]?.class_code || t('scheduleDetails')}
      bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }} // Adjust the modal body to handle overflow
      width={800} // Adjust the width of the modal if needed
    >
      <Table
        dataSource={popupData}
        columns={columns}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: 'max-content' }} // Enable horizontal scrolling if necessary
      />
    </Modal>
  );
};

export default ScheduleModal;
