import React, { useState } from 'react';
import axios from 'axios';
import './InventoryPage.css'; 
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { notification, Form, Input, Button, Select, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Option } = Select;

const InventoryPage = () => {
  const { t } = useTranslation();
  const [newItem, setNewItem] = useState({
    product_id: '',
    item_name: '',
    item_description: '',
    category: '',
    price_per_unit: 0.0
  });

  const API_URL = process.env.REACT_APP_API_URL;

  // Function to handle form input changes
  const handleInputChange = (name, value) => {
    setNewItem((prevItem) => ({
      ...prevItem,
      [name]: value
    }));
  };

  // Function to add a new inventory item
  const handleAddItem = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/inventory`, newItem, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Show success notification
      notification.success({
        message: t('Success'),
        description: t('Inventory item added successfully.'),
      });

      // Clear form inputs after successful addition
      setNewItem({
        product_id: '',
        item_name: '',
        item_description: '',
        category: '',
        price_per_unit: 0.0
      });
    } catch (error) {
      console.error('Error adding inventory item:', error);

      // Show error notification
      notification.error({
        message: t('Error'),
        description: t('Failed to add inventory item. Please try again.'),
      });
    }
  };

  return (
    <div className="main-content">
      <Sidebar />
      <Header />
      <h1>{t('Inventory Management')}</h1>

      <Form
        layout="vertical"
        onFinish={handleAddItem}
        className="inventory-form"
      >
        <Form.Item
          label={t('Product ID')}
          name="product_id"
          rules={[{ required: true, message: t('Please enter the product ID') }]}
        >
          <Input
            value={newItem.product_id}
            onChange={(e) => handleInputChange('product_id', e.target.value)}
            placeholder={t('Enter Product ID')}
          />
        </Form.Item>

        <Form.Item
          label={t('Item Name')}
          name="item_name"
          rules={[{ required: true, message: t('Please enter the item name') }]}
        >
          <Input
            value={newItem.item_name}
            onChange={(e) => handleInputChange('item_name', e.target.value)}
            placeholder={t('Enter Item Name')}
          />
        </Form.Item>

        <Form.Item
          label={t('Item Description')}
          name="item_description"
        >
          <TextArea
            value={newItem.item_description}
            onChange={(e) => handleInputChange('item_description', e.target.value)}
            placeholder={t('Enter Item Description')}
            rows={4}
          />
        </Form.Item>

        <Form.Item
          label={t('Category')}
          name="category"
          rules={[{ required: true, message: t('Please select a category') }]}
        >
          <Select
            value={newItem.category}
            onChange={(value) => handleInputChange('category', value)}
            placeholder={t('Select a category')}
          >
            <Option value="Test">{t('Test')}</Option>
            <Option value="IELTS Book">{t('IELTS Book')}</Option>
            <Option value="TOEFL Book">{t('TOEFL Book')}</Option>
            <Option value="GED Book">{t('GED Book')}</Option>
            <Option value="IGCSE Book">{t('IGCSE Book')}</Option>
            <Option value="Other Book">{t('Other Book')}</Option>
            <Option value="Equipment">{t('Equipment')}</Option>
            <Option value="Others">{t('Others')}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t('Price per Unit')}
          name="price_per_unit"
          rules={[{ required: true, message: t('Please enter the price per unit') }]}
        >
          <Input
            type="number"
            step="0.01"
            value={newItem.price_per_unit}
            onChange={(e) => handleInputChange('price_per_unit', parseFloat(e.target.value))}
            placeholder={t('Enter Price per Unit')}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          {t('Add Item')}
        </Button>
      </Form>
    </div>
  );
};

export default withRoleAccess(InventoryPage, ['superadmin', 'admin']);
