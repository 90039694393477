import React, { useState, useEffect } from 'react';
import { Layout, Tabs, Alert } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import EditDeleteTeacher from './EditDeleteTeacher';
import EditDeleteStudent from './EditDeleteStudent';
import EditDeleteSubject from './EditDeleteSubject';
import FilterTaskTable from '../Class/FilterTaskTable';
import ClassChange from '../Class/ClassChange';
import Classes from '../Class/Classes';
import EditDeleteClassroom from './EditDeleteClassroom';
import AnnouncementAdmin from '../Announcement/AnnouncementAdmin';
import MatchTeacherSubject from '../SubjectMatch/MatchTeacherSubject';
import CreateTeacherForm from '../CreateUserform/CreateTeacherForm';
import CreateStudentForm from '../CreateUserform/CreateStudentForm';
import TutorClassLog from '../Teacher/TutorClassLog';
import CreateCourse from '../Class/CreateCourse';

const { Content } = Layout;
const { TabPane } = Tabs;

function AdminPanel() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('teachers');
  const [showCreateTeacherForm, setShowCreateTeacherForm] = useState(false);
  const [showCreateStudentForm, setShowCreateStudentForm] = useState(false);
  const [tabChangeKey, setTabChangeKey] = useState(null); // Used to signal data refetch

  const toggleCreateTeacherForm = () => {
    setShowCreateTeacherForm(!showCreateTeacherForm);
  };

  const toggleCreateStudentForm = () => {
    setShowCreateStudentForm(!showCreateStudentForm);
  };

  useEffect(() => {
    // Update tabChangeKey whenever the tab changes to trigger a data fetch in the selected component
    setTabChangeKey(activeTab);
  }, [activeTab]);

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ marginTop: '60px', marginLeft: '70px', padding: '24px' }}>
          <Alert
            message={<strong>{t('warning')}</strong>}
            description={t('warningMessage')}
            type="warning"
            showIcon
            icon={<ExclamationCircleOutlined />}
            className="alert-warning"
          />
          <Tabs
            activeKey={activeTab}
            onChange={setActiveTab}
            className="tabs-container"
          >
            <TabPane tab={t('tutors')} key="teachers">
              {showCreateTeacherForm ? (
                <CreateTeacherForm onClose={toggleCreateTeacherForm} />
              ) : (
                <EditDeleteTeacher onCreateNewTeacher={toggleCreateTeacherForm} shouldFetch={tabChangeKey === 'teachers'} />
              )}
            </TabPane>
            <TabPane tab={t('students')} key="students">
              {showCreateStudentForm ? (
                <CreateStudentForm onClose={toggleCreateStudentForm} />
              ) : (
                <EditDeleteStudent onCreateNewStudent={toggleCreateStudentForm} shouldFetch={tabChangeKey === 'students'} />
              )}
            </TabPane>
            <TabPane tab={t('subjects')} key="subjects">
              <EditDeleteSubject shouldFetch={tabChangeKey === 'subjects'} />
            </TabPane>
            <TabPane tab={t('classrooms')} key="classrooms">
              <EditDeleteClassroom shouldFetch={tabChangeKey === 'classrooms'} />
            </TabPane>
            <TabPane tab={t('createCourse1')} key="class">
              <Classes shouldFetch={tabChangeKey === 'class'} />
            </TabPane>
            <TabPane tab={t('createCourse2')} key="createcourse">
              <CreateCourse shouldFetch={tabChangeKey === 'createcourse'} />
            </TabPane>
            <TabPane tab={t('manageCourses')} key="courses">
              <FilterTaskTable shouldFetch={tabChangeKey === 'courses'} />
            </TabPane>
            <TabPane tab={t('classchange')} key="classchange">
              <ClassChange shouldFetch={tabChangeKey === 'classchange'} />
            </TabPane>
            <TabPane tab={t('announcements')} key="announcements">
              <AnnouncementAdmin shouldFetch={tabChangeKey === 'announcements'} />
            </TabPane>
            <TabPane tab={t('matchteachersubject')} key="matchteachersubject">
              <MatchTeacherSubject shouldFetch={tabChangeKey === 'matchteachersubject'} />
            </TabPane>
            <TabPane tab={t('teacherhourlog')} key="teacherhourlog">
              <TutorClassLog shouldFetch={tabChangeKey === 'teacherhourlog'} />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRoleAccess(AdminPanel, ['admin', 'superadmin']);
