import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { Button, Select, Table, message, Modal, Tabs } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './MatchTeacherSubject.css';

const { confirm } = Modal;
const { Option } = Select;
const { TabPane } = Tabs;

const MatchTeacherSubject = () => {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [matches, setMatches] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [activeTab, setActiveTab] = useState('THAI');

  const [matchedSelectedSubject, setMatchedSelectedSubject] = useState('');
  const [matchedSelectedLanguage, setMatchedSelectedLanguage] = useState('');

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const [teachersResponse, subjectsResponse, matchesResponse] = await Promise.all([
        axios.get(`${API_URL}/teachers`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${API_URL}/subjects`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${API_URL}/match/match-teacher-to-subject`, { headers: { Authorization: `Bearer ${token}` } }),
      ]);

      setTeachers(teachersResponse.data);
      setSubjects(subjectsResponse.data);
      setMatches(matchesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error(t('Error fetching data'));
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleMatch = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/match/match-teacher-to-subject`,
        { teacherId: selectedTeacher, subjectId: selectedSubject, language: selectedLanguage },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      message.success(t('Match created successfully!'));
      fetchData();
    } catch (error) {
      console.error('Error creating match:', error);
      message.error(t('Error creating match'));
    }
  };

  const handleDeleteMatch = (matchId) => {
    confirm({
      title: t('Are you sure you want to delete this match?'),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${API_URL}/match/match-teacher-to-subject/${matchId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          message.success(t('Match deleted successfully!'));
          fetchData();
        } catch (error) {
          console.error('Error deleting match:', error);
          message.error(t('Error deleting match'));
        }
      },
    });
  };

  useEffect(() => {
    const filterTeachers = () => {
      if (matchedSelectedSubject && matchedSelectedLanguage) {
        const filtered = matches
          .filter(
            (match) =>
              match.subject_id === parseInt(matchedSelectedSubject) &&
              match.language === matchedSelectedLanguage
          )
          .map((match) => ({
            firstname: match.teacher_firstname,
            lastname: match.teacher_lastname,
            nickname: match.teacher_nickname,
          }));
        setFilteredTeachers(filtered);
      } else {
        setFilteredTeachers([]);
      }
    };

    filterTeachers();
  }, [matchedSelectedSubject, matchedSelectedLanguage, matches]);

  const sortedSubjects = useMemo(
    () => [...subjects].sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' })),
    [subjects]
  );

  return (
    <div>
      <h2>{t('matchTeacherToSubject')}</h2>
      <div className="match-container">
        {/* Match Teacher to Subject Box */}
        <div className="match-box">
          <h3>{t('matchingTeachers')}</h3>
          <Select
            value={selectedTeacher}
            onChange={setSelectedTeacher}
            placeholder={t('selectATeacher')}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            {teachers.map((teacher) => (
              <Option key={teacher.userid} value={teacher.userid}>
                {teacher.nickname} {teacher.firstname} {teacher.lastname}
              </Option>
            ))}
          </Select>
          <Select
            value={selectedSubject}
            onChange={setSelectedSubject}
            placeholder={t('selectASubject')}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            {subjects.map((subject) => (
              <Option key={subject.subjectid} value={subject.subjectid}>
                {subject.title}
              </Option>
            ))}
          </Select>
          <Select
            value={selectedLanguage}
            onChange={setSelectedLanguage}
            placeholder={t('selectALanguage')}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            <Option value="Thai">{t('thai')}</Option>
            <Option value="English">{t('english')}</Option>
          </Select>
          <Button type="primary" onClick={handleMatch}>
            {t('createMatch')}
          </Button>
        </div>

        {/* Matched Teachers Box */}
        <div className="matched-box">
          <h3>{t('matchedTeachers')}</h3>
          <Select
            value={matchedSelectedSubject}
            onChange={setMatchedSelectedSubject}
            placeholder={t('selectASubject')}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            {subjects.map((subject) => (
              <Option key={subject.subjectid} value={subject.subjectid}>
                {subject.title}
              </Option>
            ))}
          </Select>
          <Select
            value={matchedSelectedLanguage}
            onChange={setMatchedSelectedLanguage}
            placeholder={t('selectALanguage')}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            <Option value="Thai">{t('thai')}</Option>
            <Option value="English">{t('english')}</Option>
          </Select>
          <div>
            {filteredTeachers.length > 0 ? (
              <ul>
                {filteredTeachers.map((teacher, index) => (
                  <li key={index}>
                    {teacher.nickname} {teacher.firstname} {teacher.lastname}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('noTeachersFound')}</p>
            )}
          </div>
        </div>
      </div>

      {/* Tab Buttons */}
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab={t('thai')} key="THAI"></TabPane>
        <TabPane tab={t('english')} key="ENGLISH"></TabPane>
      </Tabs>

      {/* Grid Container for Matches */}
      <div className="grid-container-match">
        {sortedSubjects.map((subject) => {
          const subjectMatches = matches.filter(
            (match) =>
              match.subject_id.toString() === subject.subjectid.toString() &&
              match.language.toLowerCase() === activeTab.toLowerCase()
          );

          return (
            <div key={subject.subjectid} className="grid-item-match">
              <h4>{subject.title}</h4>
              <ul>
                {subjectMatches.map((match) => (
                  <li key={match.id}>
                    {match.teacher_nickname} {match.teacher_firstname} {match.teacher_lastname}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>

      {/* Manage Matched Teachers Table */}
      <Table
        className="match-table"
        dataSource={matches}
        columns={[
          {
            title: t('teacher'),
            dataIndex: 'teacher_nickname',
            render: (nickname, record) =>
              `${nickname} ${record.teacher_firstname} ${record.teacher_lastname}`,
          },
          { title: t('subject'), dataIndex: 'subject_title' },
          { title: t('language'), dataIndex: 'language' },
          {
            title: t('actions'),
            render: (_, record) => (
              <Button type="link" danger onClick={() => handleDeleteMatch(record.id)}>
                delete
              </Button>
            ),
          },
        ]}
        rowKey="id"
      />
    </div>
  );
};

export default withRoleAccess(MatchTeacherSubject, ['superadmin', 'admin']);
