import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import PayStubModal from './PayStubModal';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import { Button, Table, notification, Typography, Input, Select } from 'antd';
import { CSVLink } from 'react-csv';
import { PDFDocument } from 'pdf-lib';
import { useTranslation } from 'react-i18next';
import './Teacher.css';

const { Title } = Typography;
const { Option } = Select;

const TeacherPaymentReport = () => {
  const { t } = useTranslation();
  const [paymentReports, setPaymentReports] = useState([]);
  const [selectedPayStub, setSelectedPayStub] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'Invalid Date';
    }
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
  };

  useEffect(() => {
    const fetchPaymentReports = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teacher-hours/teacher-payment-report`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPaymentReports(response.data);
      } catch (error) {
        console.error(t('errorFetchingPaymentReports'), error);
        notification.error({ message: t('errorFetchingPaymentReports') });
      }
    };

    fetchPaymentReports();
  }, [API_URL, t]);

  const formatTotalAmount = (amount) =>
    typeof amount === 'string' && !isNaN(parseFloat(amount))
      ? parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : '0.00';

  const handlePayStubClick = async (report) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teacher-hours/teacher-payment-report/${report.batch_id}/details`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const classDetails = response.data.map((cls) => ({
        classId: cls.class_id,
        rate: cls.hourly_rate,
        hours: cls.hours_worked,
        total: cls.total_amount,
      }));

      const payStubData = {
        companyLogo: '/images/Theplanner.png',
        companyName: 'PLANN TECH',
        companyAddress: 'Address Bangkok',
        companyWebsite: 'www.plann.tech',
        companyPhone: 'Tel. 0987654321',
        companyLine: 'Line: @planntech',
        teacherName: report.teacher_name,
        teacherAddress: report.address,
        teacherPhone: report.phone,
        teacherNationalId: report.nationalid,
        payPeriod: report.period,
        payDate: report.approval_date,
        classes: classDetails,
        totalHours: report.total_hours,
        totalPay: report.total_amount,
        withholdingTax: report.sum_withholdingtax,
      };

      setSelectedPayStub(payStubData);
    } catch (error) {
      console.error(t('errorFetchingClassDetails'), error);
      notification.error({ message: t('errorFetchingClassDetails') });
    }
  };

  const generateTawi50PDF = async (report) => {
    try {
      const existingPdfBytes = await fetch('/images/tawi50.pdf').then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();

      form.getTextField('name1').setText('PLANN TECH CO., LTD');
      form.getTextField('add1').setText('770/122 Patio Pattanakarn 30 Suan Luang Bangkok 10250');
      form.getTextField('id1').setText('0103363883993');
      form.getTextField('name2').setText(report.teacher_name || '');
      form.getTextField('add2').setText(report.address || '');
      form.getTextField('id1_2').setText(report.nationalid || '');

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Tawi50_${report.teacher_name}_${report.period}.pdf`;
      link.click();
    } catch (error) {
      console.error('Error generating Tawi50 PDF:', error);
      notification.error({ message: t('errorGeneratingPDF') });
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
  };

  const filteredReports = paymentReports.filter((report) => {
    const matchesSearchTerm = report.teacher_name.toLowerCase().includes(searchTerm);
    const matchesPeriod = selectedPeriod === 'All Period' || selectedPeriod === '' || report.period === selectedPeriod;
    return matchesSearchTerm && matchesPeriod;
  });

  const columns = [
    { title: t('approvalDate'), dataIndex: 'approval_date', key: 'approval_date', render: formatDate },
    { title: t('period'), dataIndex: 'period', key: 'period' },
    { title: t('teacherName'), dataIndex: 'teacher_name', key: 'teacher_name' },
    { title: t('nationalID'), dataIndex: 'nationalid', key: 'nationalid' },
    { title: t('totalHours'), dataIndex: 'total_hours', key: 'total_hours' },
    { title: t('subAmount'), dataIndex: 'sub_amount', key: 'sub_amount', render: formatTotalAmount },
    { title: t('sumWithholdingTax'), dataIndex: 'sum_withholdingtax', key: 'sum_withholdingtax', render: formatTotalAmount },
    { title: t('totalPay'), dataIndex: 'total_amount', key: 'total_amount', render: formatTotalAmount },
    { title: t('approveby'), dataIndex: 'approval_userid', key: 'approval_userid' },
    {
      title: t('paystub'),
      key: 'paystub',
      render: (_, report) => (
        <Button onClick={() => handlePayStubClick(report)}>{t('viewpaystub')}</Button>
      ),
    },
    {
      title: t('tawi50'),
      key: 'tawi50',
      render: (_, report) => (
        <Button onClick={() => generateTawi50PDF(report)}>{t('Generate Tawi50')}</Button>
      ),
    },
  ];

  const csvHeaders = [
    { label: 'Approval Date', key: 'approval_date' },
    { label: 'Period', key: 'period' },
    { label: 'Teacher Name', key: 'teacher_name' },
    { label: 'National ID', key: 'nationalid' },
    { label: 'Total Hours', key: 'total_hours' },
    { label: 'Sub Amount', key: 'sub_amount' },
    { label: 'Withholding Tax', key: 'sum_withholdingtax' },
    { label: 'Total Pay', key: 'total_amount' },
    { label: 'Approved By', key: 'approval_userid' },
  ];

  return (
    <div>
      <Sidebar />
      <Header />
      <div>
        <div style={{ marginBottom: '20px' }}>
          <Input
            placeholder={t('searchByName')}
            onChange={handleSearch}
            style={{ width: '200px', marginRight: '20px' }}
          />
          <Select
            placeholder={t('Filter By Period')}
            onChange={handlePeriodChange}
            style={{ width: '200px', marginRight: '20px' }}
          >
            <Option value="All Period">{t('All Period')}</Option>
            {Array.from(new Set(paymentReports.map((report) => report.period))).map((period) => (
              <Option key={period} value={period}>
                {period}
              </Option>
            ))}
          </Select>
          <CSVLink
            data={filteredReports}
            headers={csvHeaders}
            filename="Teacher_Payment_Report.csv"
            style={{ textDecoration: 'none' }}
          >
            <Button>{t('exportToCSV')}</Button>
          </CSVLink>
        </div>
        <Table
          columns={columns}
          dataSource={filteredReports}
          rowKey="id"
          pagination={{ pageSize: 10 }}
        />
      </div>
      <PayStubModal show={!!selectedPayStub} onClose={() => setSelectedPayStub(null)} payStubData={selectedPayStub} />
    </div>
  );
};

export default withRoleAccess(TeacherPaymentReport, ['superadmin']);
