import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Input, Spin } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';

const EditStudentModal = ({ isOpen, onClose, onSave, studentId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (studentId) {
      const fetchStudent = async () => {
        setLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${API_URL}/students/${studentId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          form.setFieldsValue(response.data);
        } catch (error) {
          console.error('Error fetching student:', error);
          setError(t('fetchStudentError'));
        } finally {
          setLoading(false);
        }
      };

      fetchStudent();
    }
  }, [studentId, API_URL, t, form]);

  const handleSubmit = async () => {
    try {
      const values = form.getFieldsValue();
      onSave(values);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(t('submitError'));
    }
  };

  return (
    <Modal
      visible={isOpen}
      title={t('editStudent')}
      onCancel={onClose}
      onOk={handleSubmit}
      okText={t('save')}
      cancelText={t('cancel')}
    >
      {loading ? (
        <Spin tip={t('loading')} />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item label={t('firstName')} name="firstname" rules={[{ required: true, message: t('requiredField') }]}>
            <Input />
          </Form.Item>
          <Form.Item label={t('lastName')} name="lastname" rules={[{ required: true, message: t('requiredField') }]}>
            <Input />
          </Form.Item>
          <Form.Item label={t('email')} name="email" rules={[{ type: 'email', message: t('invalidEmail') }]}>
            <Input />
          </Form.Item>
          <Form.Item label={t('nickname')} name="nickname">
            <Input />
          </Form.Item>
          <Form.Item label={t('dateOfBirth')} name="date_of_birth">
            <Input type="date" />
          </Form.Item>
          <Form.Item label={t('nationalID')} name="nationalid">
            <Input />
          </Form.Item>
          <Form.Item label={t('phone')} name="phone">
            <Input />
          </Form.Item>
          <Form.Item label={t('address')} name="address">
            <Input />
          </Form.Item>
          <Form.Item label={t('photoURL')} name="photourl">
            <Input />
          </Form.Item>
          <Form.Item label={t('currentEducationLevel')} name="currenteducationlevel">
            <Input />
          </Form.Item>
          <Form.Item label={t('guardianContact')} name="guardiancontact">
            <Input />
          </Form.Item>
          <Form.Item label={t('schoolName')} name="schoolname">
            <Input />
          </Form.Item>
          <Form.Item label={t('schoolID')} name="schoolid">
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default withRoleAccess(EditStudentModal, ['admin', 'superadmin']);
