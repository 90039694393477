import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { Button, Select, Table, Pagination, message, Modal, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import './Teacher.css';

const { Option } = Select;
const { confirm } = Modal;

const generateMonthYearOptions = () => {
  const options = [];
  const startYear = 2024;
  const startMonth = 7;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let year = startYear; year <= currentYear + 5; year++) {
    for (let month = 0; month < 12; month++) {
      if (year === startYear && month < startMonth) continue;
      const monthYear = new Date(year, month).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      }).toUpperCase();
      options.push({ value: `${year}-${month + 1}`, label: monthYear });
      if (year === currentYear && month >= currentMonth) break;
    }
  }
  return options;
};

const TutorClassLog = () => {
  const { t, i18n } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const defaultMonthYear = `${currentYear}-${currentMonth}`;
  const [selectedMonthYear, setSelectedMonthYear] = useState(defaultMonthYear);
  const [statusFilter, setStatusFilter] = useState('');
  const [adminapproveFilter, setAdminapproveFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [tutorFilter, setTutorFilter] = useState('');
  const itemsPerPage = 100;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date);
  };

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClasses(response.data);
        setFilteredClasses(response.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
        message.error(t('Error fetching classes'));
      }
    };
    fetchClasses();
  }, [t]);

  useEffect(() => {
    const filtered = classes.filter((cls) => {
      const classDate = new Date(cls.date);
      const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
      const isMonthYearMatch = selectedMonthYear ? classMonthYear === selectedMonthYear : true;
      const isStatusMatch = statusFilter ? cls.status === statusFilter : true;
      const isAdminapproveMatch = adminapproveFilter ? cls.adminapprove === adminapproveFilter : true;
      const isTutorMatch = tutorFilter
        ? `(${cls.nickname}) ${cls.teacher_name}`.toLowerCase().includes(tutorFilter.toLowerCase())
        : true;
      return isMonthYearMatch && isStatusMatch && isAdminapproveMatch && isTutorMatch;
    });
    setFilteredClasses(filtered);
    setCurrentPage(1);
  }, [selectedMonthYear, statusFilter, adminapproveFilter, tutorFilter, classes]);

  const getUniqueTutors = () => {
    const tutors = classes.map(cls => `(${cls.nickname}) ${cls.teacher_name}`);
    return [...new Set(tutors)].sort();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAdminApprove = (classId, status) => {
    confirm({
      title: t('Confirm Admin Approval'),
      icon: <ExclamationCircleOutlined />,
      content: t('Are you sure you want to confirm the admin approval for this class?'),
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.put(`${process.env.REACT_APP_API_URL}/classes/${classId}/adminapprove`, {
            adminapprove: status,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setClasses(prevClasses => prevClasses.map(cls =>
            cls.classid === classId ? { ...cls, adminapprove: status } : cls
          ));
          message.success(t('Admin approval confirmed'));
        } catch (error) {
          console.error('Error confirming admin approval:', error);
          message.error(t('Error confirming admin approval'));
        }
      },
    });
  };

  const csvData = filteredClasses.map(cls => ({
    No: cls.classid,
    Date: new Date(cls.date).toLocaleDateString(),
    ClassCode: cls.class_code,
    ClassID: cls.classid,
    Subject: cls.subject_name,
    Teacher: `(${cls.nickname}) ${cls.teacher_name}`,
    Time: cls.schedule_time,
    ScheduleHour: cls.schedule_hour,
    Status: cls.status,
    AdminApprove: cls.adminapprove,
  }));

  const columns = [
    { title: t('No'), dataIndex: 'classid', key: 'classid' },
    { title: t('date'), dataIndex: 'date', key: 'date', render: formatDate },
    { title: t('classcode'), dataIndex: 'class_code', key: 'class_code' },
    { title: t('classid'), dataIndex: 'classid', key: 'classid' },
    { title: t('subject'), dataIndex: 'subject_name', key: 'subject_name' },
    { title: t('teacher'), dataIndex: 'teacher_name', key: 'teacher_name', render: (text, record) => `(${record.nickname}) ${text}` },
    { title: t('time'), dataIndex: 'schedule_time', key: 'schedule_time' },
    { title: t('scheduleHour'), dataIndex: 'schedule_hour', key: 'schedule_hour' },
    {
      title: t('confirmteach'),
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status === 'approved' ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {t('HOURSAPPROVED')}
          </Tag>
        ) : (
          <Tag icon={<ClockCircleOutlined />} color="warning">
            {t('confirmpending')}
          </Tag>
        ),
    },
    {
      title: t('adminapprove'),
      dataIndex: 'adminapprove',
      key: 'adminapprove',
      render: (adminapprove, record) =>
        adminapprove === 'adminconfirm' ? (
          <Tag color="blue">{t('Admin Confirmed')}</Tag>
        ) : (
          <Tooltip title={t('Click to confirm')}>
            <Button type="primary" shape="round" onClick={() => handleAdminApprove(record.classid, 'adminconfirm')}>
              {t('Admin Confirm')}
            </Button>
          </Tooltip>
        ),
    },
  ];

  return (
    <div className="teacher-hours">
      <div className="filter-container">
        <Select value={selectedMonthYear} onChange={setSelectedMonthYear} placeholder={t('All Months/Years')} style={{ width: 200, marginRight: 10 }}>
          {generateMonthYearOptions().map(option => <Option key={option.value} value={option.value}>{option.label}</Option>)}
        </Select>
        <Select value={tutorFilter} onChange={setTutorFilter} placeholder={t('All Tutors')} style={{ width: 200, marginRight: 10 }}>
          {getUniqueTutors().map(tutor => <Option key={tutor} value={tutor}>{tutor}</Option>)}
        </Select>
        <Select value={statusFilter} onChange={setStatusFilter} placeholder={t('classstatuses')} style={{ width: 200, marginRight: 10 }}>
          <Option value="approved">{t('confirmed')}</Option>
          <Option value="pending">{t('confirmpending')}</Option>
        </Select>
        <Select value={adminapproveFilter} onChange={setAdminapproveFilter} placeholder={t('adminapprove')} style={{ width: 200 }}>
          <Option value="adminconfirm">{t('Admin Confirmed')}</Option>
          <Option value="pending">{t('Pending')}</Option>
        </Select>
      </div>
      <CSVLink data={csvData} filename={`tutor_class_log_${selectedMonthYear || 'all'}.csv`}>
        <Button icon={<FontAwesomeIcon icon={faFileExcel} />} style={{ margin: '10px 0' }}>
          {t('exportToCSV')}
        </Button>
      </CSVLink>
      <Table dataSource={filteredClasses} columns={columns} pagination={{ current: currentPage, pageSize: itemsPerPage, total: filteredClasses.length, onChange: handlePageChange }} rowKey="classid" scroll={{ x: 'max-content' }} />
    </div>
  );
};

export default withRoleAccess(TutorClassLog, ['superadmin', 'admin']);
