import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Table, Input, Select, Button, Tooltip, Modal, notification, Spin } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import '../Dashboard.css';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { confirm } = Modal;

const UserControlPage = () => {
  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [roleFilter, setRoleFilter] = useState('');
  const [activeCounts, setActiveCounts] = useState({
    superadmin: { total: 0, active: 0 },
    admin: { total: 0, active: 0 },
    teacher: { total: 0, active: 0 },
    student: { total: 0, active: 0 },
    total: { total: 0, active: 0 },
  });
  const API_URL = process.env.REACT_APP_API_URL;
  const MAX_ACTIVE_USERS = {
    superadmin: 7,
    admin: 14,
    teacher: 51,
    student: 200,
  };
  const ITEMS_PER_PAGE = 150;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return t('neversignin');
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) return t('neversignin');
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat(i18n.language || 'en-UK', options).format(date).toUpperCase();
    const today = new Date();
    const diffTime = Math.abs(today - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return `${formattedDate} (${diffDays} ${t('daysAgo')})`;
};


  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const [usersResponse, lastSigninResponse] = await Promise.all([
        axios.get(`${API_URL}/users`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${API_URL}/users/last-signin`, { headers: { Authorization: `Bearer ${token}` } }),
      ]);
      const lastSigninMap = lastSigninResponse.data.reduce((acc, curr) => {
        acc[curr.user_id] = curr.last_signin;
        return acc;
      }, {});
      const usersWithLastSignin = usersResponse.data.map(user => ({
        ...user,
        lastSignin: lastSigninMap[user.userid] || t('neversignin'),
      }));
      const sortedUsers = usersWithLastSignin.sort((a, b) => a.userid - b.userid);
      setUsers(sortedUsers);
      setFilteredUsers(sortedUsers);
      calculateCounts(sortedUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      handleTokenError(error);
      setLoading(false);
    }
  };

  const calculateCounts = (users) => {
    const counts = {
      superadmin: { total: 0, active: 0 },
      admin: { total: 0, active: 0 },
      teacher: { total: 0, active: 0 },
      student: { total: 0, active: 0 },
      total: { total: 0, active: 0 },
    };
    users.forEach(user => {
      if (counts.hasOwnProperty(user.role)) {
        counts[user.role].total += 1;
        if (user.status === 'active') counts[user.role].active += 1;
      }
      counts.total.total += 1;
      if (user.status === 'active') counts.total.active += 1;
    });
    setActiveCounts(counts);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const results = users.filter(user => {
      const matchesSearchTerm = `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm.toLowerCase())
        || user.email.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesRole = roleFilter ? user.role === roleFilter : true;
      return matchesSearchTerm && matchesRole;
    });
    setFilteredUsers(results);
    setCurrentPage(1);
  }, [searchTerm, roleFilter, users]);

  const updateUserStatus = (userId, status, role) => {
    if (status === 'active' && activeCounts[role].active >= MAX_ACTIVE_USERS[role]) {
      notification.warning({
        message: t('alert'),
        description: t('maxLimitReached', { role: t(role), limit: MAX_ACTIVE_USERS[role] }),
      });
      return;
    }

    confirm({
      title: t('confirmStatusChange'),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.put(`${API_URL}/users/${userId}/status`, { status }, {
            headers: { Authorization: `Bearer ${token}` },
          });
          fetchUsers();
        } catch (error) {
          console.error('Error updating user status:', error);
          handleTokenError(error);
        }
      },
      onCancel() {},
    });
  };

  const resetPassword = (userId) => {
    confirm({
      title: t('confirmResetPassword'),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.put(`${API_URL}/users/${userId}/reset-password`, {}, {
            headers: { Authorization: `Bearer ${token}` },
          });
          notification.success({
            message: t('temporaryPasswordTitle'),
            description: t('temporaryPassword', { userId, tempPassword: response.data.tempPassword }),
          });
        } catch (error) {
          console.error('Error resetting password:', error);
          handleTokenError(error);
        }
      },
      onCancel() {},
    });
  };

  const handleTokenError = (error) => {
    if (error.response && error.response.status === 401) {
      refreshToken().then(() => fetchUsers()).catch(err => console.error('Token refresh failed:', err));
    }
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(`${API_URL}/refresh-token`, { refreshToken });
    localStorage.setItem('token', response.data.accessToken);
  };

  const columns = [
    { title: t('userId'), dataIndex: 'userid', key: 'userid' },
    { title: t('username'), dataIndex: 'firstname', key: 'firstname', render: (text, user) => `${user.firstname} ${user.lastname}` },
    { title: t('nickname'), dataIndex: 'nickname', key: 'nickname' },
    { title: t('email'), dataIndex: 'email', key: 'email' },
    { title: t('userRole'), dataIndex: 'role', key: 'role', render: (role) => <span className={role}>{t(role)}</span> },
    { title: t('lastSignin'), dataIndex: 'lastSignin', key: 'lastSignin', render: (formatDateTime) },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, user) => (
        <Select
          value={status}
          onChange={(value) => updateUserStatus(user.userid, value, user.role)}
          className={status === 'active' ? 'select-active' : 'select-inactive'}
        >
          <Option value="active">{t('active')}</Option>
          <Option value="inactive">{t('inactive')}</Option>
        </Select>
      ),
    },
    {
      title: t('resetPassword'),
      key: 'resetPassword',
      render: (text, user) => (
        user.userid !== 1 && (
          <Button onClick={() => resetPassword(user.userid)}>{t('resetPassword')}</Button>
        )
      ),
    },
  ];

  if (loading) return <Spin tip={t('loading')} />;

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="dashboard-content">
        <h1>{t('userControlPage')}</h1>
        <div className="role-counts">
                    {Object.keys(MAX_ACTIVE_USERS).map(role => (
                        <div className="role-count-box" key={role}>
                            <strong>{t(role)}:</strong> <br/>
                            {t('maxUser')}: {MAX_ACTIVE_USERS[role]} <br/>
                            {t('activeUser')}: {activeCounts[role].active} <br/>
                            {t('inactiveUser')}: {activeCounts[role].total - activeCounts[role].active}
                        </div>
                    ))}
                </div>
        <div className="filter-container">
          <Input
            placeholder={t('searchByName')}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{ marginBottom: 16 }}
          />
          <Select
            value={roleFilter}
            onChange={setRoleFilter}
            style={{ width: 200, marginBottom: 16 }}
            placeholder={t('ALL ROLES')}
          >
            <Option value="">{t('ALL ROLES')}</Option>
            <Option value="superadmin">{t('superadmin')}</Option>
            <Option value="admin">{t('admin')}</Option>
            <Option value="teacher">{t('teacher')}</Option>
            <Option value="student">{t('student')}</Option>
          </Select>
        </div>
        <Table
          columns={columns}
          dataSource={filteredUsers}
          rowKey="userid"
          pagination={{
            current: currentPage,
            pageSize: ITEMS_PER_PAGE,
            total: filteredUsers.length,
            onChange: setCurrentPage,
          }}
        />
      </div>
    </div>
  );
};

export default withRoleAccess(UserControlPage, ['superadmin', 'admin']);
