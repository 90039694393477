import React, { useState, useEffect } from 'react';
import { Modal, Input, Checkbox, Row, Col, Form, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';

const EditTeacherModal = ({ isOpen, onClose, onSave, teacherId }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    address: '',
    email: '',
    nickname: '',
    photourl: '',
    bachelor_degree: '',
    specialize_subjects: '',
    schedule: '',
    isparttime: false,
    bachelor_school: '',
    master_degree: '',
    master_school: '',
    doctoral_degree: '',
    doctoral_school: '',
    hoursrate: '',
    color: '',
    language: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchTeacherData = async () => {
      if (!teacherId) return;
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFormData(response.data);
        form.setFieldsValue(response.data); // Set form fields with fetched data
      } catch (error) {
        console.error('Error fetching teacher data:', error);
        setError(t('fetchTeacherError'));
        message.error(t('fetchTeacherError'));
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchTeacherData();
    }
  }, [teacherId, isOpen, API_URL, t, form]);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const { color, language, ...formDataToSubmit } = formData; // Exclude color and language

      await axios.put(`${API_URL}/teachers/${teacherId}`, formDataToSubmit, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });

      onSave({ ...formDataToSubmit, color }); // Keep color in the state
      onClose();
      message.success(t('teacherUpdated'));
    } catch (error) {
      console.error('Error updating teacher:', error);
      setError(t('updateTeacherError'));
      message.error(t('updateTeacherError'));
    }
  };

  const handleChange = (changedValues) => {
    setFormData({ ...formData, ...changedValues });
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      onOk={form.submit}
      title={t('editTeacher')}
      okText={t('save')}
      cancelText={t('cancel')}
    >
      {loading ? (
        <Spin tip={t('loading')} />
      ) : (
        <Form form={form} onValuesChange={handleChange} onFinish={handleSubmit} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t('firstName')} name="firstname">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('lastName')} name="lastname">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t('email')} name="email">
                <Input type="email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('nickname')} name="nickname">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t('photoURL')} name="photourl">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('hoursRate')} name="hoursrate">
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t('specializeSubjects')} name="specialize_subjects">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('about')} name="schedule">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t('bachelorDegree')} name="bachelor_degree">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('bachelorSchool')} name="bachelor_school">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t('masterDegree')} name="master_degree">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('masterSchool')} name="master_school">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t('doctoralDegree')} name="doctoral_degree">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('doctoralSchool')} name="doctoral_school">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="isparttime" valuePropName="checked">
            <Checkbox>{t('partTime')}</Checkbox>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default withRoleAccess(EditTeacherModal, ['admin', 'superadmin']);
