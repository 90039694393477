import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { Layout, Table, Modal, Button, Input, Select, DatePicker, Space, notification, Pagination } from 'antd';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Registrations.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const { Content } = Layout;
const { Option } = Select;

const testTypes = [
  { name: "GED", subtypes: ["Mathematical Reasoning", "Reasoning Through Language Arts", "Science", "Social Studies"] },
  { name: "IGCSE", subtypes: ["Mathematics", "English", "Sciences (Biology, Chemistry, Physics)", "History", "Geography", "Foreign Languages", "Art & Design", "Business Studies", "Computer Science"] },
  { name: "A-LEVEL", subtypes: ["Mathematics", "Further Mathematics", "Physics", "Chemistry", "Biology", "English Literature", "History", "Geography", "Economics", "Psychology"] },
  { name: "SAT", subtypes: ["SAT (General)", "SAT Subject Tests"] },
  { name: "IELTS", subtypes: ["IELTS Academic", "IELTS General Training"] },
  { name: "AP", subtypes: ["AP Calculus AB", "AP Calculus BC", "AP Physics", "AP Chemistry", "AP Biology", "AP English Language and Composition", "AP English Literature and Composition", "AP U.S. History", "AP World History", "AP Psychology"] },
  { name: "IB", subtypes: ["IB Mathematics", "IB Physics", "IB Chemistry", "IB Biology", "IB English A", "IB English B", "IB History", "IB Economics", "IB Psychology", "Theory of Knowledge (TOK)"] },
  { name: "TOEFL", subtypes: ["TOEFL iBT", "TOEFL PBT"] },
  { name: "TOEFL-MUIC", subtypes: ["General", "IBT"] },
  { name: "CU-AAT", subtypes: ["ธรรมดา", "E-Testing"] },
  { name: "CU-ATS", subtypes: ["Physics", "Chemistry"] },
  { name: "CU-TEP", subtypes: ["ธรรมดา + Speaking", "E-Testing", "E-Testing + CU-TEP E-Testing Speaking"] },
  { name: "TU-GET", subtypes: ["PBT", "CBT"] },
  { name: "OTHERS", subtypes: ["OTHERS"] }
];

const BookTest = () => {
  const { t, i18n } = useTranslation();
  const [bookings, setBookings] = useState([]);
  const [students, setStudents] = useState([]);
  const [formData, setFormData] = useState({
    student_id: '',
    test_type: '',
    sub_type: '',
    test_date: null,
    test_details: '',
    price: '',
    paid_status: 'unpaid',
  });
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchBookings = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/test-scores/test-bookings`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const sortedBookings = response.data.sort((a, b) => new Date(a.test_date) - new Date(b.test_date));
      const futureBookings = sortedBookings.filter(booking => new Date(booking.test_date) >= new Date());
      setBookings(futureBookings);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  }, [API_URL]);

  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudents(response.data.students);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchBookings();
    fetchStudents();
  }, [fetchBookings, fetchStudents]);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/test-scores/book-test`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFormData({
        student_id: '',
        test_type: '',
        sub_type: '',
        test_date: '',
        test_details: '',
        price: '',
        paid_status: 'unpaid'
      });
      setIsModalVisible(false);
      fetchBookings();
      notification.success({ message: t('Test booking submitted successfully') });
    } catch (error) {
      console.error('Error booking test:', error);
      setError(t('Error booking test'));
    }
  };

  const handleStatusUpdate = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/test-scores/update-status/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchBookings();
      notification.success({ message: t('Status updated successfully') });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const columns = [
    { title: t('Test Date'), dataIndex: 'test_date', key: 'test_date', render: (date) => new Date(date).toLocaleDateString() },
    { title: t('Student'), dataIndex: 'firstname', key: 'firstname', render: (_, record) => `${record.firstname} ${record.lastname}` },
    { title: t('Nickname'), dataIndex: 'nickname', key: 'nickname' },
    { title: t('Test'), dataIndex: 'test_type', key: 'test_type' },
    { title: t('Subject'), dataIndex: 'sub_type', key: 'sub_type' },
    { title: t('Price'), dataIndex: 'price', key: 'price' },
    { title: t('Details'), dataIndex: 'test_details', key: 'test_details' },
    {
      title: t('Status'),
      dataIndex: 'paid_status',
      key: 'paid_status',
      render: (status) => (
        <span style={{ color: status === 'paid' ? 'green' : 'red' }}>
          {status}
        </span>
      ),
    },
    {
      title: t('Update'),
      key: 'update',
      render: (_, record) => (
        record.paid_status === 'unpaid' && (
          <Button type="primary" onClick={() => handleStatusUpdate(record.id)}>
            {t('Mark as Paid')}
          </Button>
        )
      ),
    },
  ];

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <ToastContainer />
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            {t('Register for a Test')}
          </Button>
          <Modal
            title={t('Register for a Test')}
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={handleSubmit}
          >
            <form>
              <div className="form-group">
                <label>{t('Student')}</label>
                <Select
                  value={formData.student_id}
                  onChange={(value) => setFormData({ ...formData, student_id: value })}
                  placeholder={t('Select Student')}
                  style={{ width: '100%' }}
                >
                  {students.map(student => (
                    <Option key={student.userid} value={student.userid}>
                      {`${student.firstname} ${student.lastname} (${student.nickname})`}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="form-group">
                <label>{t('Test Type')}</label>
                <Select
                  value={formData.test_type}
                  onChange={(value) => setFormData({ ...formData, test_type: value })}
                  placeholder={t('Select Test Type')}
                  style={{ width: '100%' }}
                >
                  {testTypes.map(type => (
                    <Option key={type.name} value={type.name}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </div>
              {formData.test_type && (
                <div className="form-group">
                  <label>{t('Subject')}</label>
                  <Select
                    value={formData.sub_type}
                    onChange={(value) => setFormData({ ...formData, sub_type: value })}
                    placeholder={t('Select Sub Type')}
                    style={{ width: '100%' }}
                  >
                    {testTypes.find(type => type.name === formData.test_type)?.subtypes.map((subtype, index) => (
                      <Option key={index} value={subtype}>
                        {subtype}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
              <div className="form-group">
                <label>{t('Test Date')}</label>
                <DatePicker
                  value={formData.test_date ? moment(formData.test_date) : null}
                  onChange={(date) => setFormData({ ...formData, test_date: date ? date.toISOString() : null })}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="form-group">
                <label>{t('Test Details')}</label>
                <Input.TextArea
                  value={formData.test_details}
                  onChange={(e) => setFormData({ ...formData, test_details: e.target.value })}
                  rows={4}
                  placeholder={t('Enter test details')}
                />
              </div>
              <div className="form-group">
                <label>{t('Price')}</label>
                <Input
                  type="number"
                  value={formData.price}
                  onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                  placeholder={t('Enter price')}
                />
              </div>
              <div className="form-group">
                <label>{t('Paid Status')}</label>
                <Select
                  value={formData.paid_status}
                  onChange={(value) => setFormData({ ...formData, paid_status: value })}
                  style={{ width: '100%' }}
                >
                  <Option value="unpaid">{t('Unpaid')}</Option>
                  <Option value="paid">{t('Paid')}</Option>
                </Select>
              </div>
              {error && <div className="error-message">{error}</div>}
            </form>
          </Modal>

          <h2>{t('All Test Bookings')}</h2>
          <Table
            columns={columns}
            dataSource={bookings}
            pagination={{
              current: currentPage,
              pageSize: itemsPerPage,
              total: bookings.length,
              onChange: setCurrentPage,
            }}
            rowKey="id"
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(BookTest, ['admin', 'superadmin']);