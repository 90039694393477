import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Table, Select, Button, Modal, Form, Input, notification, Tabs } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import InvoiceModal from './InvoiceModal';
import PaymentDetails from './PaymentDetails';
import { useTranslation } from 'react-i18next';
import './Accounting.css';
import moment from 'moment';

const { Option } = Select;
const { TabPane } = Tabs;

const formatDate = (dateString) => {
  return moment(dateString).format('DD MMM YYYY');
};

const formatNumber = (number) => {
  if (isNaN(number)) return '0.00';
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

const Accounting = () => {
  const { t } = useTranslation();
  const [registrations, setRegistrations] = useState([]);
  const [filteredRegistrations, setFilteredRegistrations] = useState([]);
  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
    paymentMethod: '',
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [form] = Form.useForm(); 

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchRegistrations = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
  
      // First API call to fetch all registrations
      const response = await axios.get(`${API_URL}/registrations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Log the raw response data from the API
      console.log('Response Data:', response.data);
  
      const processedData = await Promise.all(
        response.data.map(async (reg) => {
          // Log each registration to check for invoice_no
          console.log('Registration:', reg);
          console.log('Invoice Number:', reg.invoiceno); // Check if invoice_no is defined
  
          // Fetch payment info for each registration
          const paymentInfo = await fetchPaymentInfo(reg.invoiceno);
  
          // Log fetched payment information
          console.log('Payment Info for Invoice:', reg.invoiceno, paymentInfo);
  
          return {
            ...reg,
            confirmation_status: reg.confirmation_status, // Assuming this exists in the response
            remaining_balance: paymentInfo ? paymentInfo.remainingBalance : reg.final_price, // Set remaining balance
          };
        })
      );
  
      setRegistrations(processedData);
      setFilteredRegistrations(processedData);
  
      // Extract payment methods for filtering
      const methods = [...new Set(processedData.map((reg) => reg.payment_method))];
      setPaymentMethods(methods);
  
      // Log the final processed data
      console.log('Processed Data:', processedData);
    } catch (error) {
      console.error('Error fetching registrations:', error);
    }
  }, [API_URL])


  const fetchPaymentInfo = async (invoice_no) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/payment/${invoice_no}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching payment info:', error);
      return null;
    }
  };
  
  // Fetch registrations when the component mounts
  useEffect(() => {
    fetchRegistrations();
  }, [fetchRegistrations]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    let filtered = registrations;

    if (filters.fromDate) {
      filtered = filtered.filter(registration => new Date(registration.date) >= new Date(filters.fromDate));
    }

    if (filters.toDate) {
      filtered = filtered.filter(registration => new Date(registration.date) <= new Date(filters.toDate));
    }

    if (filters.paymentMethod) {
      filtered = filtered.filter(registration => registration.payment_method === filters.paymentMethod);
    }

    setFilteredRegistrations(filtered);
  };

  const handleModalOk = async () => {
    try {
      const values = form.getFieldsValue();
      const paymentAmount = parseFloat(values.payment_amount);
      const remainingAmount = selectedRegistration.final_price - paymentAmount;

      const paymentData = {
        invoiceno: selectedRegistration.invoiceno,
        student_id: selectedRegistration.student_id,
        pay_amount: paymentAmount,
        remaining_balance: remainingAmount,
        payment_method: values.payment_method,
        memo: values.memo
      };

      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/payment`, paymentData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      notification.success({
        message: t('paymentConfirmed'),
        description: t('การชำระเงินได้รับการยืนยันแล้ว!'),
      });

      setIsPaymentModalOpen(false);
      form.resetFields();
      fetchRegistrations();
    } catch (error) {
      notification.error({
        message: t('error'),
        description: t('payment_log_error'),
      });
    }
  };

  const openPaymentModal = (registration) => {
    setSelectedRegistration(registration);
    setIsPaymentModalOpen(true);
  };
  
  const openInvoiceModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsInvoiceModalOpen(true);
    console.log('Opening Invoice Modal for:', transaction); 
  };
  
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false); // Close the modal
    setSelectedTransaction(null); // Clear the selected transaction data
  };
  
  const calculateTotals = () => {
    return filteredRegistrations.reduce(
      (totals, registration) => {
        totals.price += parseFloat(registration.price) || 0;
        totals.discount += parseFloat(registration.discount) || 0;
        totals.vat += parseFloat(registration.vat) || 0;
        totals.finalPrice += parseFloat(registration.final_price) || 0;
        return totals;
      },
      { price: 0, discount: 0, vat: 0, finalPrice: 0 }
    );
  };

  const totals = calculateTotals();
  const csvData = [
    ...filteredRegistrations,
    {
      invoiceno: 'TOTAL',
      date: '',
      student_name: '',
      class_code: '',
      price: totals.price,
      discount: totals.discount,
      vat: totals.vat,
      final_price: totals.finalPrice,
      payment_method: '',
      memo: '',
      admin_id: ''
    }
  ];


  

  const columns = [
    {
      title: t('invoiceNo'),
      dataIndex: 'invoiceno',
      key: 'invoiceno',
      render: (text, record) => (
        <Link onClick={() => openInvoiceModal(record)}>
          {text.replace('INV', '')}
        </Link>
      ),
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: formatDate,
    },
    {
      title: t('student'),
      dataIndex: 'student_name',
      key: 'student_name',
    },
    {
      title: t('course'),
      dataIndex: 'class_code',
      key: 'class_code',
    },
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      render: formatNumber,
    },
    {
      title: t('discount'),
      dataIndex: 'discount',
      key: 'discount',
      render: formatNumber,
    },
    {
      title: t('VAT'),
      dataIndex: 'vat',
      key: 'vat',
      render: formatNumber,
    },
    {
      title: t('total'),
      dataIndex: 'final_price',
      key: 'final_price',
      render: formatNumber,
    },
    {
      title: t('remaining'),
      dataIndex: 'remaining_balance',
      key: 'remaining_balance',
      render: (balance) =>
        balance > 0 ? (
          <span style={{ color: 'red' }}>({formatNumber(Math.abs(balance))})</span> // Wrap the balance in parentheses
        ) : (
          '0'
        ),
    },
    
    {
      title: t('payment'),
      dataIndex: 'payment_method',
      key: 'payment_method',
    },
    {
      title: t('memo'),
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: t('pay'),
      key: 'pay',
      render: (_, record) => (
        <Button onClick={() => openPaymentModal(record)} icon={<FontAwesomeIcon icon={faDollarSign} />} />
      ),
    },
  ];

  return (
    <div className="accounting-container">
      <Sidebar />
      <Header />
      <h1>{t('accounting')}</h1>

      <Tabs defaultActiveKey="1">
        <TabPane tab={t('registrationlist')} key="1">
        <div className="filter-container">
            <label>
              {t('fromDate')}:
              <input
                type="date"
                name="fromDate"
                value={filters.fromDate}
                onChange={handleFilterChange}
              />
            </label>
            <label>
              {t('toDate')}:
              <input
                type="date"
                name="toDate"
                value={filters.toDate}
                onChange={handleFilterChange}
              />
            </label>
            <label>
              {t('paymentMethod')}:
              <select
                name="paymentMethod"
                value={filters.paymentMethod}
                onChange={handleFilterChange}
              >
                <option value="">{t('all')}</option>
                {paymentMethods.map((method) => (
                  <option key={method} value={method}>
                    {method}
                  </option>
                ))}
              </select>
            </label>
            <button onClick={applyFilters} className="filter-button">
              {t('applyFilters')}
            </button>
            <CSVLink
              data={csvData}
              filename={"registrations.csv"}
              target="_blank"
            >
              <Button style={{ marginLeft: '16px' }} icon={<FontAwesomeIcon icon={faFileExcel} />}>
            {t('exportCSV')}
          </Button>
            </CSVLink>
          </div>

          <Table
            dataSource={filteredRegistrations}
            columns={columns}
            scroll={{ x: true }}
        pagination={{
          pageSize: 50,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
            rowKey="invoiceno"
          />
        </TabPane>

        <TabPane tab={t('billingHistory')} key="2">
          <PaymentDetails />
        </TabPane>
      </Tabs>

      {/* Invoice Modal */}
      {selectedTransaction && (
        <InvoiceModal
          visible={isInvoiceModalOpen}
          onClose={handleInvoiceModalClose}
          registration={selectedTransaction}
        />
      )}


      <Modal
        title={t('makePayment')}
        visible={isPaymentModalOpen}
        onOk={handleModalOk}
        onCancel={() => setIsPaymentModalOpen(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="payment_amount" label={t('paymentAmount')} rules={[{ required: true }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="payment_method" label={t('paymentMethod')} rules={[{ required: true }]}>
            <Select>
              <Option value="cash">{t('cash')}</Option>
              <Option value="credit_card">{t('creditCard')}</Option>
              <Option value="bank_transfer">{t('bankTransfer')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="memo" label={t('memo')}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default withRoleAccess(Accounting, ['admin', 'superadmin']);
