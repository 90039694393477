import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Checkbox, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';

const SubjectModal = ({ visible, onClose, onSave, subjectData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (subjectData) {
      form.setFieldsValue(subjectData);
    } else {
      form.resetFields();
    }
  }, [subjectData, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await onSave(values);
      message.success(t('changesSaved'));
      onClose();
    } catch (error) {
      console.error('Validation failed:', error);
      message.error(t('saveSubjectError'));
    }
  };

  return (
    <Modal
      visible={visible}
      title={subjectData ? t('editSubject') : t('createSubject')}
      onCancel={onClose}
      onOk={handleSubmit}
      okText={t('save')}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="subjectgroup"
          label={t('subjectGroup')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="code"
          label={t('code')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="year"
          label={t('year')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="title"
          label={t('subjecttitle')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="level"
          label={t('level')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="specification"
          label={t('specification')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={t('description')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="image_url"
          label={t('imageUrl')}
          rules={[{ required: true, message: t('fieldRequired') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="language" label={t('languages')}>
          <Checkbox.Group>
            <Checkbox value="Thai">Thai</Checkbox>
            <Checkbox value="English">English</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withRoleAccess(SubjectModal, ['admin', 'superadmin']);
