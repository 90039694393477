import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Button, Input, Table, Modal, Form, message, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import '../../Pages/Dashboard.css';

const AnnouncementAdmin = () => {
  const { t } = useTranslation();
  const [announcements, setAnnouncements] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchAnnouncements = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/announcements`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setAnnouncements(
        response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      );
    } catch (error) {
      console.error('Error fetching announcements:', error);
      message.error(t('failedToFetchAnnouncements'));
    } finally {
      setLoading(false);
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const handleSaveAnnouncement = async (values) => {
    const token = localStorage.getItem('token');
    const announcementData = { ...values, by: 'Admin' }; // Hardcoded admin name

    try {
      let response;
      if (editId) {
        response = await axios.put(`${API_URL}/announcements/${editId}`, announcementData, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAnnouncements((prev) =>
          prev.map((announcement) =>
            announcement.id === response.data.id ? response.data : announcement
          ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        );
        message.success(t('announcementUpdated'));
      } else {
        response = await axios.post(`${API_URL}/announcements`, announcementData, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAnnouncements((prev) => [response.data, ...prev].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
        message.success(t('announcementCreated'));
      }
      form.resetFields();
      setIsModalVisible(false);
      setEditId(null);
    } catch (error) {
      console.error('Error saving announcement:', error);
      message.error(t('failedToSaveAnnouncement'));
    }
  };

  const handleEdit = (announcement) => {
    form.setFieldsValue(announcement);
    setEditId(announcement.id);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${API_URL}/announcements/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAnnouncements((prev) => prev.filter((announcement) => announcement.id !== id));
      message.success(t('announcementDeleted'));
    } catch (error) {
      console.error('Error deleting announcement:', error);
      message.error(t('failedToDeleteAnnouncement'));
    }
  };

  const openModal = () => {
    form.resetFields();
    setEditId(null);
    setIsModalVisible(true);
  };

  const columns = [
    { title: t('title'), dataIndex: 'title', key: 'title' },
    { title: t('message'), dataIndex: 'message', key: 'message' },
    {
      title: t('link'),
      dataIndex: 'link',
      key: 'link',
      render: (link) =>
        link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <LinkOutlined />
          </a>
        ) : null
    },
    { title: t('by'), dataIndex: 'by', key: 'by' },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => new Date(date).toLocaleDateString()
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (_, announcement) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(announcement)}>
            {t('edit')}
          </Button>
          <Button icon={<DeleteOutlined />} danger onClick={() => handleDelete(announcement.id)}>
            {t('delete')}
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="announcement-admin-container">
      <h2>{t('announcements')}</h2>
      <Button type="primary" onClick={openModal} style={{ marginBottom: '16px' }}>
        {t('createAnnouncement')}
      </Button>
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={announcements}
          rowKey={(record) => record.id}
          pagination={{ pageSize: 10 }}
        />
      </Spin>
      <Modal
        title={editId ? t('editAnnouncement') : t('createAnnouncement')}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => form.submit()}
        okText={t('save')}
      >
        <Form form={form} layout="vertical" onFinish={handleSaveAnnouncement}>
          <Form.Item name="title" label={t('title')} rules={[{ required: true, message: t('titleRequired') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="message" label={t('message')} rules={[{ required: true, message: t('messageRequired') }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="link" label={t('link')}>
            <Input />
          </Form.Item>
          <Form.Item name="image_url" label={t('imageUrl')}>
            <Input />
          </Form.Item>
          <Form.Item name="date" label={t('date')} rules={[{ required: true, message: t('dateRequired') }]}>
            <Input type="date" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default withRoleAccess(AnnouncementAdmin, ['admin', 'superadmin']);
