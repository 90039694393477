import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { CSVLink } from 'react-csv';
import { Form, Select, Input, Button, Table, Modal, Checkbox, notification } from 'antd';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import './Teacher.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFileExcel } from '@fortawesome/free-solid-svg-icons';

const TeacherDetailsPage = () => {
  const { teacherId } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate
  const { t } = useTranslation();
  const [teacher, setTeacher] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [tempMonth, setTempMonth] = useState(new Date().getMonth() + 1);
  const [tempYear, setTempYear] = useState(new Date().getFullYear());
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [hourlyRates, setHourlyRates] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const [remarks, setRemarks] = useState({});
  const [withholdingTax, setWithholdingTax] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'Invalid Date';
    }
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
  };

  const fetchTeacher = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTeacher(response.data);
    } catch (error) {
      console.error(t('errorFetchingTeacher'), error);
    }
  }, [teacherId, API_URL, t]);

  const fetchClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/teacher/${teacherId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setClasses(response.data);
      filterClasses(response.data, selectedMonth, selectedYear);
    } catch (error) {
      console.error(t('errorFetchingClasses'), error);
    }
  }, [teacherId, selectedMonth, selectedYear, API_URL, t]);

  const fetchApprovedClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teacher-hours/${teacherId}/approved`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          period: `${selectedYear}-${selectedMonth < 10 ? '0' + selectedMonth : selectedMonth}`,
        },
      });

      if (response.data.length > 0) {
        setIsApproved(true);
        setFilteredClasses(response.data);
        const rates = {};
        response.data.forEach(({ class_id, hourly_rate }) => {
          rates[class_id] = hourly_rate;
        });
        setHourlyRates(rates);
      } else {
        setIsApproved(false);
      }
    } catch (error) {
      console.error(t('errorFetchingApprovedClasses'), error);
    }
  }, [teacherId, selectedMonth, selectedYear, API_URL, t]);

  useEffect(() => {
    fetchTeacher();
    fetchClasses();
    fetchApprovedClasses();
  }, [fetchTeacher, fetchClasses, fetchApprovedClasses]);

  const filterClasses = (classes, month, year) => {
    const filtered = classes.filter(cls => {
      const classDate = new Date(cls.date);
      return classDate.getMonth() + 1 === month && classDate.getFullYear() === year;
    });
    setFilteredClasses(filtered);
  };

  const handleSelect = () => {
    setSelectedMonth(tempMonth);
    setSelectedYear(tempYear);
    filterClasses(classes, tempMonth, tempYear);
  };

  const handleRateChange = (classId, value) => {
    setHourlyRates({
      ...hourlyRates,
      [classId]: parseFloat(value) || 0,
    });
  };

  const handleRemarkChange = (classId, value) => {
    setRemarks(prevRemarks => ({
      ...prevRemarks,
      [classId]: value,
    }));
  };

  const handleApprove = () => {
    const approvedClassesData = filteredClasses.map(cls => ({
      classId: cls.classid,
      hourlyRate: hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0),
      hoursWorked: convertScheduleHourToDecimal(cls.schedule_hour),
      remark: remarks[cls.classid] || '',
    }));
    setConfirmData({
      teacherName: `${teacher.firstname} ${teacher.lastname}`,
      period: `${selectedMonth}/${selectedYear}`,
      approvedClasses: approvedClassesData,
    });
    setShowConfirmModal(true);
  };

  const confirmApproval = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/teacher-hours/${teacherId}/approve`, {
        period: `${selectedYear}-${selectedMonth < 10 ? '0' + selectedMonth : selectedMonth}`,
        approvedClasses: confirmData.approvedClasses,
        totalAmount: confirmData.totalAmount,
        withholdingTaxApplied: withholdingTax,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      notification.success({
        message: t('success'),
        description: t('teacherHoursApprovalSaved'),
      });

      setShowConfirmModal(false);
      fetchClasses();
      fetchApprovedClasses();
    } catch (error) {
      console.error(t('errorApprovingHours'), error);
      notification.error({
        message: t('error'),
        description: t('errorApprovingHours'),
      });
    }
  };

  const convertScheduleHourToDecimal = (scheduleHour) => {
    if (!scheduleHour) return 0;
    const [hours, minutes] = scheduleHour.split('h').map(part => part.trim().replace('m', ''));
    const hoursDecimal = parseInt(hours, 10) || 0;
    const minutesDecimal = parseInt(minutes, 10) / 60 || 0;
    return hoursDecimal + minutesDecimal;
  };

  const formatAmount = (amount) => {
    return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleExportCSV = () => {
    return filteredClasses.map(cls => ({
      'Teacher ID': teacher ? teacher.userid : '',
      'Teacher Name': teacher ? `${teacher.firstname} ${teacher.lastname}` : '',
      'Class Code': cls.class_code,
      'Class ID': cls.classid,
      'Subject Name': cls.subject_name,
      'Date': cls.date,
      'Schedule Hour': cls.schedule_hour,
      'Hourly Rate': hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0),
      'Total Hours': convertScheduleHourToDecimal(cls.schedule_hour).toFixed(2),
      'Total Amount': formatAmount(convertScheduleHourToDecimal(cls.schedule_hour) * (hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0))),
      'Status': cls.status,
      'Payout': cls.payout,
    }));
  };

  return (
    <div style={{ marginTop: '70px', marginLeft: '80px', marginRight: '20px' }}>
      <Sidebar />
      <Header />
      <div>
        <Button onClick={() => navigate(-1)} style={{ marginBottom: '20px' }}>{t('back')}</Button>
        <h1>{t('teacherDetails')}</h1>
        {teacher && (
          <div>
            <h3>({teacher.nickname}) {teacher.firstname} {teacher.lastname}</h3>
            <p>{t('teacherID')}: {teacher.userid}</p>
            <p>{t('currentHourlyRate')}: {teacher.hoursrate}</p>

            <Form layout="inline" style={{ marginBottom: '20px' }}>
              <Form.Item label={t('month')}>
                <Select value={tempMonth} onChange={setTempMonth}>
                  {Array.from({ length: 12 }, (_, i) => (
                    <Select.Option key={i + 1} value={i + 1}>
                      {new Date(0, i).toLocaleString('default', { month: 'long' })}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('year')}>
                <Select value={tempYear} onChange={setTempYear}>
                  {Array.from({ length: 5 }, (_, i) => (
                    <Select.Option key={i} value={new Date().getFullYear() - i}>
                      {new Date().getFullYear() - i}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button onClick={handleSelect} icon={<FontAwesomeIcon icon={faSearch} />} />
              </Form.Item>
              <Form.Item>
                <CSVLink data={handleExportCSV()} filename={`approved_hours_${teacherId}_${selectedMonth}_${selectedYear}.csv`}>
                  <Button icon={<FontAwesomeIcon icon={faFileExcel} />}>{t('exportToCSV')}</Button>
                </CSVLink>
              </Form.Item>
            </Form>

            <Table
              dataSource={filteredClasses}
              columns={[
                { title: t('classCode'), dataIndex: 'class_code', key: 'class_code' },
                { title: t('id'), dataIndex: 'classid', key: 'classid' },
                { title: t('subjectName'), dataIndex: 'subject_name', key: 'subject_name' },
                { title: t('type'), dataIndex: 'isprivate', key: 'isprivate', render: (isprivate) => isprivate ? t('private') : t('group') },
                { title: t('date'), dataIndex: 'date', key: 'date', render: (formatDate) },
                { title: t('hours'), dataIndex: 'schedule_hour', key: 'schedule_hour' },
                {
                  title: t('rate'),
                  dataIndex: 'hourlyRate',
                  key: 'hourlyRate',
                  render: (text, record) => (
                    <Input
                      type="number"
                      value={hourlyRates[record.classid] || (teacher ? teacher.hoursrate : 0)}
                      onChange={(e) => handleRateChange(record.classid, e.target.value)}
                      disabled={isApproved}
                    />
                  ),
                },
                { title: t('note'), dataIndex: 'note', key: 'note' },
                {
                  title: t('remark'),
                  key: 'remark',
                  render: (_, record) => (
                    <Input
                      value={remarks[record.classid] || ''}
                      onChange={(e) => handleRemarkChange(record.classid, e.target.value)}
                      placeholder={t('enterRemark')}
                    />
                  ),
                },
              ]}
              rowKey="classid"
              pagination={{ pageSize: 10 }}
              scroll={{ x: true }}
            />

            <Button type="primary" onClick={handleApprove}>
              {t('approveHoursAndPayment')}
            </Button>
          </div>
        )}

        <Modal
          title={t('confirm_payment')}
          visible={showConfirmModal}
          onCancel={() => setShowConfirmModal(false)}
          onOk={confirmApproval}
        >
          <p>{t('teacherName')}: {confirmData.teacherName}</p>
          <p>{t('period')}: {confirmData.period}</p>
          <Checkbox
            checked={withholdingTax}
            onChange={() => setWithholdingTax(!withholdingTax)}
          >
            {t('sumWithholdingTax')}
          </Checkbox>
        </Modal>
      </div>
    </div>
  );
};

export default withRoleAccess(TeacherDetailsPage, ['superadmin']);
