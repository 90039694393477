import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, DatePicker, Button } from 'antd';
import { useTranslation } from 'react-i18next'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv'; // Import CSVLink for CSV export
import moment from 'moment'; // For handling date formatting
import ReceiptModal from './ReceiptModal'; // Import the ReceiptModal component

const { Search } = Input;
const { MonthPicker } = DatePicker;

const PaymentDetails = () => {
  const { t } = useTranslation();

  const [paymentDetails, setPaymentDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(''); // Search term for student name
  const [selectedMonthYear, setSelectedMonthYear] = useState(null); // Month/Year filter
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false); // State for modal visibility
  const [selectedReceipt, setSelectedReceipt] = useState(null);

  const formatNumber = (number) => {
    if (isNaN(number)) return '0.00';
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  const fetchPaymentDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Fetching all payment details...');
      
      const response = await axios.get(`${API_URL}/payment/allpayments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Payment Details Response:', response.data);
      
      setPaymentDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching payment details:', error);
      setLoading(false);
    }
  };

  // Function to handle search input
  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  // Function to handle month/year picker
  const handleMonthYearChange = (date) => {
    setSelectedMonthYear(date ? date.format('YYYY-MM') : null);
  };

  // Function to open the receipt modal
  const openReceiptModal = (record) => {
    setSelectedReceipt(record); // Set the selected receipt data
    setIsReceiptModalOpen(true); // Open the modal
  };
  
  // Function to close the receipt modal
  const closeReceiptModal = () => {
    setIsReceiptModalOpen(false);
    setSelectedReceipt(null); // Clear the selected receipt
  };

  // Filter payment details based on search and month/year
  const filteredPaymentDetails = paymentDetails.filter((payment) => {
    const matchesSearch =
      payment.firstname.toLowerCase().includes(searchTerm) ||
      payment.lastname.toLowerCase().includes(searchTerm);

    const matchesMonthYear =
      !selectedMonthYear ||
      moment(payment.timestamp).format('YYYY-MM') === selectedMonthYear;

    return matchesSearch && matchesMonthYear;
  });

  const columns = [
    {
      title: t('invoiceNo'),
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: t('firstName'),
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: t('lastName'),
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: t('subject'),
      dataIndex: 'class_code',
      key: 'class_code',
    },
    {
      title: t('paymentMethod'),
      dataIndex: 'payment_method',
      key: 'payment_method',
    },
    {
      title: t('subtotal'),
      dataIndex: 'final_price',
      key: 'final_price',
      render: (finalPrice) => `${formatNumber(finalPrice)}`,
    },
    {
      title: t('nettotal'),
      dataIndex: 'pay_amount',
      key: 'pay_amount',
      render: (amount) => <span style={{ color: 'green' }}> 
          {formatNumber(amount)}</span>
    },
    {
      title: t('remaining'),
      dataIndex: 'remaining_balance',
      key: 'remaining_balance',
      render: (balance) =>
        balance === 0 ? (
          <span style={{ color: 'green' }}>0</span>
        ) : (
          <span style={{ color: 'red' }}>{formatNumber(balance)}</span>
        ),
    },
    {
      title: t('memo'),
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: t('at'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => new Date(timestamp).toLocaleString(),
    },
    {
      title: t('receipt'),
      key: 'receipt',
      render: (_, record) => (
        <Button onClick={() => openReceiptModal(record)}>
          <FontAwesomeIcon icon={faReceipt} />
        </Button>
      ),
    },
  ];

  // Prepare data for CSV export
  const csvData = filteredPaymentDetails.map(payment => ({
    invoiceNo: payment.invoice_no,
    firstName: payment.firstname,
    lastName: payment.lastname,
    subject: payment.class_code,
    paymentMethod: payment.payment_method,
    subtotal: formatNumber(payment.final_price),
    nettotal: formatNumber(payment.pay_amount),
    remaining: formatNumber(payment.remaining_balance),
    memo: payment.memo || '',
    timestamp: new Date(payment.timestamp).toLocaleString(),
  }));

  return (
    <div className="payment-details-page">
      <div style={{ marginBottom: '16px' }}>
        <Search
          placeholder={t('searchByName')}
          onSearch={handleSearch}
          style={{ width: 200, marginRight: '16px' }}
        />
        <MonthPicker
          placeholder={t('selectMonthYear')}
          onChange={handleMonthYearChange}
          style={{ marginRight: '16px' }}
        />
        <Button onClick={fetchPaymentDetails}>{t('refresh')}</Button>

        {/* Add CSV Export Button */}
        <CSVLink
          data={csvData}
          filename="payment_details.csv"
          target="_blank"
        >
          <Button style={{ marginLeft: '16px' }} icon={<FontAwesomeIcon icon={faFileExcel} />}>
            {t('exportCSV')}
          </Button>
        </CSVLink>
      </div>

      {loading ? (
        <p>{t('loading')}</p>
      ) : (
        <Table
          dataSource={filteredPaymentDetails}
          columns={columns}
          rowKey="id"
          scroll={{ x: true }}
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      )}

      {/* Add the ReceiptModal component */}
      {isReceiptModalOpen && (
        <ReceiptModal
        show={isReceiptModalOpen}
        onClose={closeReceiptModal}
        registration={selectedReceipt}
      />
      
      )}
    </div>
  );
};

export default PaymentDetails;
