import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Table, Select, Button, Pagination, Space, notification, Typography, Tag } from 'antd';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './Teacher.css';

const { Option } = Select;
const { Title } = Typography;

const generateMonthYearOptions = () => {
  const options = [];
  const startYear = 2024;
  const startMonth = 7; // August (0-indexed, so January is 0, August is 7)
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let year = startYear; year <= currentYear + 5; year++) {
    for (let month = 0; month < 12; month++) {
      if (year === startYear && month < startMonth) continue;
      const monthYear = new Date(year, month).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      }).toUpperCase();
      options.push({ value: `${year}-${month + 1}`, label: monthYear });
      if (year === currentYear && month >= currentMonth) break;
    }
  }
  return options;
};

const TutorClassLog = () => {
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const defaultMonthYear = `${currentYear}-${currentMonth}`;

  const [selectedMonthYear, setSelectedMonthYear] = useState(defaultMonthYear);
  const [statusFilter, setStatusFilter] = useState('');
  const [adminapproveFilter, setAdminapproveFilter] = useState('');
  const [payoutFilter, setPayoutFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 150;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'Invalid Date';
    }
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
  };

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClasses(response.data);
        setFilteredClasses(response.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
        notification.error({ message: 'Error fetching classes' });
      }
    };
    fetchClasses();
  }, []);

  useEffect(() => {
    const filtered = classes.filter((cls) => {
      const classDate = new Date(cls.date);
      const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
      const isMonthYearMatch = selectedMonthYear ? classMonthYear === selectedMonthYear : true;
      const isStatusMatch = statusFilter ? cls.status === statusFilter : true;
      const isAdminapproveMatch = adminapproveFilter ? cls.adminapprove === adminapproveFilter : true;
      const isPayoutMatch = payoutFilter ? cls.payout === payoutFilter : true;
      return isMonthYearMatch && isStatusMatch && isAdminapproveMatch && isPayoutMatch;
    });

    setFilteredClasses(filtered);
    setCurrentPage(1);
  }, [selectedMonthYear, statusFilter, adminapproveFilter, payoutFilter, classes]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentClasses = filteredClasses.slice(startIndex, startIndex + itemsPerPage);

  const monthYearOptions = generateMonthYearOptions();

  const csvData = filteredClasses.map((cls) => ({
    No: cls.classid,
    Date: new Date(cls.date).toLocaleDateString(),
    ClassCode: cls.class_code,
    ClassID: cls.classid,
    Subject: cls.subject_name,
    Teacher: cls.teacher_name,
    Time: cls.schedule_time,
    ScheduleHour: cls.schedule_hour,
    Status: cls.status,
    AdminApprove: cls.adminapprove,
    Payout: cls.payout,
  }));

  const columns = [
    { title: t('No'), dataIndex: 'classid', key: 'classid', render: (_, __, index) => startIndex + index + 1 },
    { title: t('Date'), dataIndex: 'date', key: 'date', render: (formatDate) },
    { title: t('Class Code'), dataIndex: 'class_code', key: 'class_code' },
    { title: t('Class ID'), dataIndex: 'classid', key: 'classid' },
    { title: t('Subject'), dataIndex: 'subject_name', key: 'subject_name' },
    { title: t('Teacher'), dataIndex: 'teacher_name', key: 'teacher_name', render: (name, cls) => `(${cls.nickname}) ${name}` },
    { title: t('Time'), dataIndex: 'schedule_time', key: 'schedule_time' },
    { title: t('Schedule Hour'), dataIndex: 'schedule_hour', key: 'schedule_hour' },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color = '';
        let text = '';
        
        if (status === 'approved') {
          color = 'success';
          text = t('HOURSAPPROVED');
        } else {
          color = 'warning';
          text = t('confirmpending');
        }
        
        return (
          <Tag color={color} style={{ minWidth: '90px', textAlign: 'center' }}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: t('Admin Approve'),
      dataIndex: 'adminapprove',
      key: 'adminapprove',
      render: (adminapprove) => {
        let color = '';
        let text = '';
        
        if (adminapprove === 'adminconfirm') {
          color = 'blue';
          text = t('adminconfirmed');
        } else {
          color = 'orange';
          text = t('confirmpending');
        }
        
        return (
          <Tag color={color} style={{ minWidth: '90px', textAlign: 'center' }}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: t('Payout'),
      dataIndex: 'payout',
      key: 'payout',
      render: (payout) => {
        let color = '';
        let text = '';
        
        if (payout === 'paid') {
          color = 'green';
          text = t('Paid');
        } else {
          color = 'gold';
          text = t('Pending');
        }
        
        return (
          <Tag color={color} style={{ minWidth: '90px', textAlign: 'center' }}>
            {text}
          </Tag>
        );
      },
    },
  ];

  return (
    <div className="teacher-hours">
      <Space style={{ marginBottom: 16 }}>
        <Select
          value={selectedMonthYear}
          onChange={setSelectedMonthYear}
          className="filter-select"
          placeholder={t('All Months/Years')}
        >
          {monthYearOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        <Select
          value={statusFilter}
          onChange={setStatusFilter}
          className="filter-select"
          placeholder={t('classstatuses')}
        >
          <Option value="">{t('All Hours Status')}</Option>
          <Option value="approved">{t('confirmed')}</Option>
          <Option value="pending">{t('confirmpending')}</Option>
        </Select>
        <Select
          value={adminapproveFilter}
          onChange={setAdminapproveFilter}
          className="filter-select"
          placeholder={t('adminapprove')}
        >
          <Option value="">{t('All Admin Confirm')}</Option>
          <Option value="adminconfirm">{t('Admin Confirmed')}</Option>
          <Option value="pending">{t('Pending')}</Option>
        </Select>
        <Select
          value={payoutFilter}
          onChange={setPayoutFilter}
          className="filter-select"
          placeholder={t('payout')}
        >
          <Option value="">{t('All Payout Status')}</Option>
          <Option value="pending">{t('Pending')}</Option>
          <Option value="paid">{t('Paid')}</Option>
        </Select>
      </Space>

      <Button type="primary">
        <CSVLink data={csvData} filename={`tutor_class_log_${selectedMonthYear || 'all'}.csv`}>
          <FontAwesomeIcon icon={faFileExcel} /> {t('exportToCSV')}
        </CSVLink>
      </Button>

      <Table
  columns={columns}
  dataSource={currentClasses}
  rowKey="classid"
  pagination={{
    current: currentPage,
    pageSize: itemsPerPage,
    total: filteredClasses.length,
    onChange: setCurrentPage,
  }}
  scroll={{ x: 'max-content' }} // Added scroll property
/>

    </div>
  );
};

export default withRoleAccess(TutorClassLog, ['superadmin', 'admin']);
