import React, { useRef, useState } from 'react';
import './PayStubModal.css';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const formatNumber = (number, locale) => {
  if (isNaN(number)) {
    return '0.00';
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

const formatDate = (dateString, locale) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString(locale, options).toUpperCase();
};

const PayStubModal = ({ show, onClose, payStubData }) => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef(); // Ref for the modal content
  const [isButtonHidden, setIsButtonHidden] = useState(false); // State to manage button visibility

  if (!show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close the modal if the click is outside the modal content
    }
  };

  const handleDownloadPDF = () => {
    setIsButtonHidden(true); // Hide the button before capturing
    const element = componentRef.current;

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 10, 10, 190, 0); // Adjust width and height to fit content
      pdf.save('paystub.pdf');
      setIsButtonHidden(false); // Show the button again after capturing
    });
  };

  const calculateSubtotal = (rate, hours) => rate * hours;
  const calculateWithholdingTax = (subtotal) => subtotal * 0.03;
  const calculateTotal = (subtotal, withholdingTax) => subtotal - withholdingTax;

  const calculatedClasses = payStubData.classes.map(cls => {
    const subtotal = calculateSubtotal(cls.rate, cls.hours);
    const withholdingTax = calculateWithholdingTax(subtotal);
    const total = calculateTotal(subtotal, withholdingTax);

    return {
      ...cls,
      subtotal,
      withholdingTax,
      total
    };
  });

  const totalSubtotal = calculatedClasses.reduce((acc, cls) => acc + cls.subtotal, 0);
  const totalWithholdingTax = calculatedClasses.reduce((acc, cls) => acc + cls.withholdingTax, 0);
  const totalPay = calculatedClasses.reduce((acc, cls) => acc + cls.total, 0);

  return (
    <div className="paystub-modal-overlay" onClick={handleOverlayClick}>
      <div className="paystub-modal" ref={componentRef}>
        <span className="modal-close-button" onClick={onClose}>&times;</span>
        <div className="paystub-header">
          <div className="invoice-header-left">
            <img
              src={payStubData.companyLogo}
              alt="PLANN TECH"
              className="invoice-logo"
              style={{ width: '100px', height: 'auto' }}
            />
            <div className="company-info">
              <p><strong>{payStubData.companyName}</strong></p>
              <p>{payStubData.companyAddress}</p>
              <p>{payStubData.companyWebsite}</p>
              <p>{payStubData.companyPhone}</p>
              <p>{payStubData.companyLine}</p>
            </div>
          </div>

          <div className="invoice-header-right">
            <h3>{t('paystub')}</h3>
            <p>{t('Pay Period')}: {payStubData.payPeriod}</p>
            <p>{t('Pay Date')}: {formatDate(payStubData.payDate, i18n.language)}</p>
          </div>
        </div>

        <div className="employee-info">
          <h3>{t('Employee Info')}</h3>
          <p>{t('name')}: {payStubData.teacherName}</p>
          <p>{t('address')}: {payStubData.teacherAddress}</p>
          <p>{t('phone')}: {payStubData.teacherPhone}</p>
          <p>{t('nationalID')}: {payStubData.teacherNationalId}</p>
        </div>

        <div className="paystub-table">
          <table>
            <thead>
              <tr>
                <th>{t('class')}</th>
                <th>{t('hourly_rate')}</th>
                <th>{t('hours')}</th>
                <th>{t('subtotal')}</th>
                <th>{t('sumWithholdingTax')}</th>
                <th>{t('nettotal')}</th>
              </tr>
            </thead>
            <tbody>
              {calculatedClasses.map(cls => (
                <tr key={cls.classId}>
                  <td>{cls.classId}</td>
                  <td>{formatNumber(cls.rate, i18n.language)}</td>
                  <td>{cls.hours}</td>
                  <td>{formatNumber(cls.subtotal, i18n.language)}</td>
                  <td>{formatNumber(cls.withholdingTax, i18n.language)}</td>
                  <td>{formatNumber(cls.total, i18n.language)}</td>
                </tr>
              ))}
              <tr>
                <td><strong>{t('Total')}</strong></td>
                <td></td>
                <td>{payStubData.totalHours}</td>
                <td>{formatNumber(totalSubtotal, i18n.language)}</td>
                <td>{formatNumber(totalWithholdingTax, i18n.language)}</td>
                <td><strong>{formatNumber(totalPay, i18n.language)}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Conditionally render the button */}
        {!isButtonHidden && (
          <div className="paystub-actions">
            <button className="downloadpdf" onClick={handleDownloadPDF}>{t('Download as PDF')}</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PayStubModal;
