import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFlask, faCalculator, faAtom, faQuoteLeftAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const iconMapping = {
    'English': faQuoteLeftAlt,
    'Physics': faFlask,
    'Mathematics': faCalculator,
    'Chemistry': faAtom
};

const SubjectCard = ({ subject, onDetailsClick }) => {
    const { t } = useTranslation();
    const subjectIcon = iconMapping[subject.subjectgroup] || faBook;

    return (
        <div className="subject-card">
            <div className="card-image">
                <img src={subject.image_url || 'default-image.jpg'} alt={subject.title} />
            </div>
            <div className="card-header">
                <h3>{subject.title}</h3>
                <FontAwesomeIcon icon={subjectIcon} className="subject-icon" />
            </div>
            <div className="card-body">
                <p>{t('code')}: {subject.code}</p>
                <p>{t('year')}: {subject.year}</p>
                <p>{t('level')}: {subject.level}</p>
            </div>
            <div className="card-footer">
                <button onClick={() => onDetailsClick(subject)}>{t('viewDetails')}</button>
            </div>
        </div>
    );
};

export default SubjectCard;
