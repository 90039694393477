import React, { useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { Form, Input, Button, DatePicker, Checkbox, Upload, Typography, Modal, message, Row, Col } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, UploadOutlined } from '@ant-design/icons';
import SuccessModal from './SuccessModal';
import { useTranslation } from 'react-i18next';
import './CreateUserForm.css';

const { Title } = Typography;

const initialFormData = {
  firstname: '',
  lastname: '',
  nickname: '',
  nationalid: '',
  date_of_birth: '',
  email: '',
  password: '',
  role: 'student',
  phone: '',
  address: '',
  currentEducationLevel: '',
  guardianContact: '',
  schoolName: '',
  nationalIdPdf: null,
};

function CreateStudentForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (date, dateString) => {
    setFormData(prevData => ({ ...prevData, date_of_birth: dateString }));
  };

  const handleFileChange = (info) => {
    if (info.file.status === 'done') {
      setFormData(prevData => ({ ...prevData, nationalIdPdf: info.file.originFileObj }));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setFormData(prevData => ({ ...prevData, password }));
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const dataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => dataToSend.append(key, value));

      await axios.post(`${API_URL}/users/create`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success('User created successfully!');
      setFormData(initialFormData);
      setShowConfirmModal(false);
    } catch (error) {
      if (error.response && error.response.data.message === "Email address is already in use") {
        message.error('Email address is already in use. Please try another email.');
      } else {
        message.error('An error occurred while creating the user. Please try again later.');
      }
    }
  };

  return (
    <div className="form-page">
      <Sidebar />
      <Header />
      <div className="form-content">
        <Title level={2}>{t('form.add_new_student')}</Title>
        <Form layout="vertical" onFinish={() => setShowConfirmModal(true)}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label={t('form.first_name')} required>
                <Input name="firstname" value={formData.firstname} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.last_name')} required>
                <Input name="lastname" value={formData.lastname} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.nickname')} required>
                <Input name="nickname" value={formData.nickname} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.national_id')} required>
                <Input name="nationalid" value={formData.nationalid} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.national_id_pdf')} required>
                <Upload onChange={handleFileChange}>
                  <Button icon={<UploadOutlined />}>{t('form.upload_pdf')}</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.date_of_birth')} required>
                <DatePicker onChange={handleDateChange} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.current_education_level')} required>
                <Input name="currentEducationLevel" value={formData.currentEducationLevel} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.email')} required>
                <Input name="email" value={formData.email} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.phone')} required>
                <Input name="phone" value={formData.phone} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.parent_phone')} required>
                <Input name="guardianContact" value={formData.guardianContact} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('form.temporary_password')} required>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  suffix={
                    <Button
                      icon={showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                      onClick={togglePasswordVisibility}
                      type="text"
                    />
                  }
                />
                <Button onClick={generateRandomPassword} style={{ marginTop: '8px' }}>
                  {t('form.generate_password')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('form.address')} required>
                <Input.TextArea name="address" value={formData.address} onChange={handleInputChange} />
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" htmlType="submit">
            {t('form.create_student')}
          </Button>
        </Form>
        {showConfirmModal && (
          <Modal
            visible={showConfirmModal}
            title={t('form.confirm_details')}
            onCancel={() => setShowConfirmModal(false)}
            onOk={handleSubmit}
          >
            <SuccessModal details={formData} onClose={() => setShowConfirmModal(false)} onConfirm={handleSubmit} />
          </Modal>
        )}
      </div>
    </div>
  );
}

export default withRoleAccess(CreateStudentForm, ['admin', 'superadmin']);
