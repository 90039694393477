import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Modal, Input, Select, message } from 'antd';
import { EditOutlined, DeleteOutlined, HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';

function EditDeleteClassroom() {
  const { t } = useTranslation();
  const [classrooms, setClassrooms] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentClassroom, setCurrentClassroom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const [formValues, setFormValues] = useState({
    number: '',
    capacity: '',
    availability: true,
  });

  useEffect(() => {
    const fetchClassrooms = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classrooms`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClassrooms(response.data);
      } catch (error) {
        console.error('Error fetching classrooms:', error);
        setError(t('fetchClassroomsError'));
      } finally {
        setLoading(false);
      }
    };

    fetchClassrooms();
  }, [API_URL, t]);

  const openModal = (classroom) => {
    setCurrentClassroom(classroom);
    setFormValues({
      number: classroom?.number || '',
      capacity: classroom?.capacity || '',
      availability: classroom?.availability || true,
    });
    setIsModalVisible(true);
  };

  const handleDelete = (classroomId) => {
    Modal.confirm({
      title: t('confirmDelete'),
      content: t('areYouSureToDelete'),
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${API_URL}/classrooms/${classroomId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setClassrooms((prev) => prev.filter((classroom) => classroom.classroomid !== classroomId));
          message.success(t('classroomDeleted'));
        } catch (error) {
          console.error('Error deleting classroom:', error);
          message.error(t('deleteClassroomError'));
        }
      },
    });
  };

  const handleSaveClassroom = async () => {
    try {
      const token = localStorage.getItem('token');
      let response;
      if (currentClassroom) {
        // Editing existing classroom
        response = await axios.put(`${API_URL}/classrooms/${currentClassroom.classroomid}`, formValues, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClassrooms((prev) =>
          prev.map((classroom) => (classroom.classroomid === response.data.classroomid ? response.data : classroom))
        );
        message.success(t('classroomUpdated'));
      } else {
        // Creating new classroom
        response = await axios.post(`${API_URL}/classrooms`, formValues, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClassrooms((prev) => [...prev, response.data]);
        message.success(t('classroomCreated'));
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error saving classroom:', error);
      message.error(t('saveClassroomError'));
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSelectChange = (value) => {
    setFormValues((prevValues) => ({ ...prevValues, availability: value }));
  };

  const columns = [
    {
      title: t('number'),
      dataIndex: 'number',
      key: 'number',
      sorter: (a, b) => a.number.localeCompare(b.number),
    },
    {
      title: t('capacity'),
      dataIndex: 'capacity',
      key: 'capacity',
    },
    {
      title: t('availability'),
      dataIndex: 'availability',
      key: 'availability',
      render: (available) => (available ? t('available') : t('notAvailable')),
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (_, classroom) => (
        <div className="action-buttons">
          <Button
            icon={<EditOutlined style={{ color: '#1890ff' }} />} // Set icon color to blue
            onClick={() => openModal(classroom)}
            style={{ border: 'none', background: 'transparent' }} // Optional: Transparent background
          />
          <Button
            icon={<DeleteOutlined style={{ color: '#ff4d4f' }} />} // Set icon color to red
            danger
            onClick={() => handleDelete(classroom.classroomid)}
            style={{ border: 'none', background: 'transparent' }} // Optional: Transparent background
          />
        </div>
      ),
    }
  ];

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="classroomtable-container">
      <div className="admincontainer">
      <Button
        type="primary"
        icon={<HomeOutlined />}
        onClick={() => openModal(null)}
        style={{ marginBottom: '20px' }} // Added margin bottom
      >
        {t('createNewClassroom')}
      </Button>
        <Table
          dataSource={classrooms}
          columns={columns}
          rowKey="classroomid"
          pagination={{ pageSize: 10 }}
          className="task-list-table"
        />
        <Modal
          title={currentClassroom ? t('editClassroom') : t('createClassroom')}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={handleSaveClassroom}
        >
          <form>
            <label>{t('number')}</label>
            <Input
              type="text"
              name="number"
              value={formValues.number}
              onChange={handleFormChange}
            />
            <label>{t('capacity')}</label>
            <Input
              type="number"
              name="capacity"
              value={formValues.capacity}
              onChange={handleFormChange}
            />
            <label>{t('availability')}</label>
            <Select
              value={formValues.availability}
              onChange={handleSelectChange}
              style={{ width: '100%' }}
            >
              <Select.Option value={true}>{t('available')}</Select.Option>
              <Select.Option value={false}>{t('notAvailable')}</Select.Option>
            </Select>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default withRoleAccess(EditDeleteClassroom, ['admin', 'superadmin']);
