import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, message, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import SubjectModal from './SubjectModal';

const { confirm } = Modal;

function EditDeleteSubject() {
  const { t } = useTranslation();
  const [subjects, setSubjects] = useState([]);
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [currentSubject, setCurrentSubject] = useState(null);
  const [loading, setLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchSubjects = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/subjects`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const sortedSubjects = response.data.sort((a, b) => a.subjectid - b.subjectid);
        setSubjects(sortedSubjects);
      } catch (error) {
        console.error('Error fetching subjects:', error);
        message.error(t('fetchSubjectsError'));
      } finally {
        setLoading(false);
      }
    };

    fetchSubjects();
  }, [API_URL, t]);

  const handleEdit = (subject) => {
    setCurrentSubject(subject);
    setShowSubjectModal(true);
  };

  const handleDelete = (subjectId) => {
    confirm({
      title: t('confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: t('areYouSureToDelete'),
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${API_URL}/subjects/${subjectId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setSubjects((prev) => prev.filter((subject) => subject.subjectid !== subjectId));
          message.success(t('subjectDeleted'));
        } catch (error) {
          console.error('Error deleting subject:', error);
          message.error(t('deleteSubjectError'));
        }
      },
    });
  };

  const handleSaveSubject = async (subjectData) => {
    try {
      const token = localStorage.getItem('token');
      let response;
      if (currentSubject) {
        response = await axios.put(`${API_URL}/subjects/${currentSubject.subjectid}`, subjectData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubjects((prev) =>
          prev.map((subject) => (subject.subjectid === response.data.subjectid ? response.data : subject))
        );
        message.success(t('subjectUpdated'));
      } else {
        response = await axios.post(`${API_URL}/subjects`, subjectData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubjects((prev) => [...prev, response.data]);
        message.success(t('subjectCreated'));
      }
      setShowSubjectModal(false);
    } catch (error) {
      console.error('Error saving subject:', error);
      message.error(t('saveSubjectError'));
    }
  };

  const columns = [
    { title: t('code'), dataIndex: 'code', key: 'code' },
    { title: t('group'), dataIndex: 'subjectgroup', key: 'group' },
    { title: t('subjecttitle'), dataIndex: 'title', key: 'title' },
    { title: t('level'), dataIndex: 'level', key: 'level' },
    { title: t('specification'), dataIndex: 'specification', key: 'specification' },
    { title: t('description'), dataIndex: 'description', key: 'description' },
    {
      title: t('actions'),
      key: 'actions',
      render: (text, record) => (
        <div className="action-buttons">
          <Button
            icon={<EditOutlined style={{ color: '#1890ff' }} />} // Set icon color to blue
            onClick={() => handleEdit(record)} // Keep the existing functionality
            style={{ border: 'none', background: 'transparent' }} // Optional: Transparent background
          />
          <Button
            icon={<DeleteOutlined style={{ color: '#ff4d4f' }} />} // Set icon color to red
            danger
            onClick={() => handleDelete(record.subjectid)} // Keep the existing functionality
            style={{ border: 'none', background: 'transparent' }} // Optional: Transparent background
          />
        </div>
      ),
    }
    
  ];

  if (loading) return <Spin tip={t('loading')} />;

  return (
    <div className="subject-table-container">
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setCurrentSubject(null);
          setShowSubjectModal(true);
        }}
        style={{ marginBottom: '16px' }}
      >
        {t('createSubject')}
      </Button>
      <Table
        dataSource={subjects}
        columns={columns}
        rowKey="subjectid"
        pagination={{ pageSize: 100 }}
        scroll={{ x: 'max-content' }} // Add this line for horizontal scrolling
      />
      <SubjectModal
        visible={showSubjectModal}
        onClose={() => setShowSubjectModal(false)}
        onSave={handleSaveSubject}
        subjectData={currentSubject}
      />
    </div>
  );
}

export default withRoleAccess(EditDeleteSubject, ['admin', 'superadmin']);
