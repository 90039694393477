import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './Subject.css';

const SubjectDetailsModal = ({ subject, onClose }) => {
    const { t } = useTranslation();

    if (!subject) return null;

    return (
        <div className="modal-overlay-subject">
            <div className="modal-content-subject">
                <div className="modal-close-icon" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className="modal-body">
                    <div className="image-container">
                        <img src={subject.image_url || 'default-image.jpg'} alt={subject.title} />
                    </div>
                    <div className="details-container">
                        <h2>{subject.title}</h2>
                        <p><strong>{t('id')}:</strong> {subject.subjectid}</p>
                        <p><strong>{t('group')}:</strong> {subject.subjectgroup}</p>
                        <p><strong>{t('code')}:</strong> {subject.code}</p>
                        <p><strong>{t('year')}:</strong> {subject.year}</p>
                        <p><strong>{t('level')}:</strong> {subject.level}</p>
                        <p><strong>{t('specification')}:</strong> {subject.specification}</p>
                        <p><strong>{t('description')}:</strong> {subject.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubjectDetailsModal;
