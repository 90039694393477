import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import { Table, Button, Input, Select, Checkbox, notification, Tabs, Spin } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Sales.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import CurrentInventory from '../Inventory/CurrentInventory';
import InventoryPage from '../Inventory/InventoryPage';
import InventoryReport from '../Inventory/InventoryReport';

const { Option } = Select;
const { TabPane } = Tabs;
const SalesReport = lazy(() => import('./SalesReport')); // Lazy load SalesReport component

const Sales = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('sales');
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentDetails, setStudentDetails] = useState({});
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [vat, setVat] = useState(0);
  const [vatChecked, setVatChecked] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [error, setError] = useState(null);
  const categories = ['All', 'TEST', 'IELTS Book', 'TOEFL Book', 'GED Book', 'IGCSE Book', 'Other Book', 'Equipment', 'Others'];
  const [selectedCategory, setSelectedCategory] = useState('All');
  const API_URL = process.env.REACT_APP_API_URL;

  const formatPrice = (price) => new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);

  // Fetch students from the API
  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, { headers: { Authorization: `Bearer ${token}` } });
      setStudents(response.data.students || []);
      setFilteredStudents(response.data.students || []);
    } catch (error) {
      console.error('Error fetching students:', error);
      setError(t('failedToFetchStudents'));
    }
  }, [API_URL, t]);

  const fetchItems = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/inventory`, { headers: { Authorization: `Bearer ${token}` } });
      setItems(response.data.filter(item => item.status === 'active'));
    } catch (error) {
      console.error('Error fetching inventory items:', error);
      setError(t('failedToFetchItems'));
    }
  }, [API_URL, t]);

  const calculateTotals = useCallback(() => {
    const total = selectedItems.reduce((acc, item) => acc + (item.price_per_unit * item.quantity), 0);
    const vatAmount = vatChecked ? (total - discount) * 0.07 : 0;
    setVat(vatAmount);
    setTotalAmount(total);
    setGrandTotal(total - discount + vatAmount);
  }, [selectedItems, discount, vatChecked]);

  useEffect(() => {
    fetchStudents();
    fetchItems();
  }, [fetchStudents, fetchItems]);

  useEffect(() => {
    calculateTotals();
  }, [selectedItems, discount, vatChecked, calculateTotals]);

  const handleStudentSelect = (value) => {
    const student = students.find(student => student.userid === value);
    setSelectedStudent(value);
    setStudentDetails(student || {});
  };

  const handleVatCheckboxChange = (e) => setVatChecked(e.target.checked);

  const handleAddItem = (item) => {
    const existingItem = selectedItems.find(selectedItem => selectedItem.inventory_id === item.inventory_id);
    if (existingItem) {
      setSelectedItems(selectedItems.map(selectedItem =>
        selectedItem.inventory_id === item.inventory_id
          ? { ...selectedItem, quantity: selectedItem.quantity + 1 }
          : selectedItem
      ));
    } else {
      setSelectedItems([...selectedItems, { ...item, quantity: 1 }]);
    }
  };

  const handleCategorySelect = (category) => setSelectedCategory(category);

  const filteredItems = selectedCategory === 'All'
    ? items
    : items.filter(item => item.category === selectedCategory);

  const handleRemoveItem = (inventory_id) => {
    setSelectedItems(selectedItems.filter(item => item.inventory_id !== inventory_id));
  };

  const handleConfirmPurchase = async () => {
    const saleData = { student_id: selectedStudent, items: selectedItems, discount, vat, payment_method: paymentMethod };
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/sales/log-sale`, saleData, { headers: { Authorization: `Bearer ${token}` } });
      notification.success({ message: t('success'), description: t('saleLoggedSuccessfully') });
      setSelectedStudent(null);
      setSelectedItems([]);
      setVat(0);
      setDiscount(0);
      setPaymentMethod('');
    } catch (error) {
      console.error('Error logging sale:', error);
      notification.error({ message: t('error'), description: t('failedToLogSale') });
    }
  };

  return (
    <div className="pos-system">
      <Sidebar />
      <Header />
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Sales" key="sales">
          <div className="pos-layout">
            <div className="categories-section">
              <h2>{t('Category')}</h2>
              {categories.map(category => (
                <Button
                  key={category}
                  type={selectedCategory === category ? 'primary' : 'default'}
                  onClick={() => handleCategorySelect(category)}
                  style={{ margin: '4px' }}
                >
                  {category}
                </Button>
              ))}
            </div>
            <div className="menu-section">
              <div className="items-grid">
                {filteredItems.map(item => (
                  <div key={item.inventory_id} className="item-card">
                    <h3>{item.item_name}</h3>
                    <p>{formatPrice(item.price_per_unit)} {t('baht')}</p>
                    <Button type="primary" onClick={() => handleAddItem(item)}>{t('add')}</Button>
                  </div>
                ))}
              </div>
            </div>
            <div className="cart-section">
              <div className="student-select">
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder={t('selectOrSearchStudent')}
                  optionFilterProp="children"
                  onChange={handleStudentSelect}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                  {filteredStudents.map(student => (
                    <Option key={student.userid} value={student.userid}>
                      {`${student.firstname} ${student.lastname} (${student.schoolid})`}
                    </Option>
                  ))}
                </Select>
                {selectedStudent && (
                  <div className="student-details">
                    <p>{`${studentDetails.firstname} ${studentDetails.lastname}`}</p>
                    <p>{`${t('schoolid')}: ${studentDetails.schoolid}`}</p>
                    <p>{`${t('nationalid')}: ${studentDetails.nationalid}`}</p>
                  </div>
                )}
              </div>
              <div className="cart-items">
                <Table
                  dataSource={selectedItems}
                  columns={[
                    { title: t('itemname'), dataIndex: 'item_name', key: 'item_name' },
                    {
                      title: t('priceperunit'),
                      dataIndex: 'price_per_unit',
                      key: 'price_per_unit',
                      render: (price) => `${formatPrice(price)} ${t('baht')}`,
                    },
                    { title: t('quantity'), dataIndex: 'quantity', key: 'quantity' },
                    {
                      title: t('action'),
                      key: 'action',
                      render: (_, item) => (
                        <Button type="danger" onClick={() => handleRemoveItem(item.inventory_id)}>{t('delete')}</Button>
                      ),
                    },
                  ]}
                  pagination={false}
                  rowKey="inventory_id"
                />
              </div>
              <div className="cart-summary">
                <div className="subtotal">
                  <span>{t('subtotal')}</span>
                  <span>{formatPrice(totalAmount)} {t('baht')}</span>
                </div>
                <div className="discount">
                  <Input
                    addonBefore={t('discount')}
                    value={discount}
                    onChange={(e) => setDiscount(Number(e.target.value) || 0)}
                    type="number"
                  />
                </div>
                <div className="vat">
                  <Checkbox checked={vatChecked} onChange={handleVatCheckboxChange}>
                    {t('vat')} (7%)
                  </Checkbox>
                </div>
                <div className="total">
                  <span>{t('total')}</span>
                  <span>{formatPrice(grandTotal)} {t('baht')}</span>
                </div>
                <div className="payment">
                  <Select
                    placeholder={t('selectPaymentMethod')}
                    onChange={setPaymentMethod}
                    value={paymentMethod}
                    style={{ width: '100%' }}
                  >
                    <Option value="">{t('selectPaymentMethod')}</Option>
                    <Option value="fullpay">{t('fullPay')}</Option>
                    <Option value="paymentplan">{t('installment')}</Option>
                    <Option value="scholarship">{t('scholarship')}</Option>
                  </Select>
                </div>
                <Button type="primary" className="pay-button" onClick={handleConfirmPurchase}>
                  {t('confirm')} {formatPrice(grandTotal)} {t('baht')}
                </Button>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Sales Report" key="salesReport">
          <Suspense fallback={<Spin />}>
            <SalesReport />
          </Suspense>
        </TabPane>
        <TabPane tab="Current Inventory" key="currentInventory">
          <CurrentInventory />
        </TabPane>
        <TabPane tab="Add Inventory" key="add">
          <InventoryPage />
        </TabPane>
        <TabPane tab="Inventory Report" key="report">
          <InventoryReport />
        </TabPane>
      </Tabs>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default withRoleAccess(Sales, ['superadmin', 'admin']);
