import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="powered-by">
      Powered by PLANN TECH CO.,LTD.
    </div>
  </footer>
);

export default Footer;
