import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './AnnouncementList.css';
import { useTranslation } from 'react-i18next';

const AnnouncementPage = () => {
  const { i18n } = useTranslation();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/announcements`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const latestAnnouncements = response.data.slice(0, 5); // Get the latest 5 announcements
        setAnnouncements(latestAnnouncements);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching announcements:', error);
        setError('Failed to fetch announcements. Please try again later.');
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, [API_URL]);

  if (loading) {
    return <p>Loading announcements...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="newsletter-container">
      <Sidebar />
      <Header />
      <header className="newsletter-header">
        <h1>SCHOOL NEWSLETTER</h1>
      </header>
      <div className="newsletter-content">
        <div className="newsletter-left">
          <section className="newsletter-about">
            <h2>About Us</h2>
            <span>Our school is dedicated to providing a high-quality education to our students.</span>
            </section>
          <section className="newsletter-events">
            <h2>Upcoming Events</h2> 
            {announcements.map((announcement) => (
              <div key={announcement.id} className="event-detail">
                <h3>{announcement.title}</h3>
                <p>{announcement.message}</p>
                <p>{formatDate(announcement.date)}</p>
                {announcement.link && (
                  <a href={announcement.link} target="_blank" rel="noopener noreferrer" className="announcement-link">
                    More Info
                  </a>
                )}
              </div>
            ))}
          </section>
        </div>
        <div className="newsletter-right">
          {announcements.map((announcement, index) => (
            announcement.image_url ? (
              <img
                key={index}
                src={announcement.image_url}
                alt="School Poster"
                className="newsletter-image"
              />
            ) : (
              <div key={index} className="image-placeholder">
                300px x 475px
              </div>
            )
          ))}
        </div>
      </div>
      <section className="newsletter-reminders">
        <h2>Reminders</h2>
        <p>Homework and Assignments: Please continue to check the online platform/classroom bulletin board for daily homework and ongoing assignments. Consistent review and completion of assignments greatly contribute to your child's academic success.</p>
      </section>
    </div>
  );
};


export default withRoleAccess(AnnouncementPage, ['admin', 'superadmin', 'teacher', 'student']);